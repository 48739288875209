import React from "react";
import {
  Flex,
  Grid,
  Text,
} from "@chakra-ui/react";
import { CalendarIcon } from "../../Icons";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DateInputs = ({ startDate, endDate, setDates }) => {
  return (
    <>
        <Text
          color="rgba(37, 39, 51, 0.85)"
          fontSize={18}
          fontWeight={500}
          mb="35px"
        >
          Select the time frame of the data you would like to export
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" rowGap="30px" columnGap="25px" mb="50px">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderRadius="4px"
            px="20px"
            background="rgba(159, 162, 180, 0.02)"
            border="1px solid rgba(159, 162, 180, 0.4)"
            height="55px"
          >
            <DatePicker
              selected={startDate}
              onChange={(date) => setDates('start', date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              value={startDate || 'Date From'}
              className="fields-datepicker"
              dateFormat="dd/MM/yyyy"
            />
            <CalendarIcon/>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderRadius="4px"
            px="20px"
            background="rgba(159, 162, 180, 0.02)"
            border="1px solid rgba(159, 162, 180, 0.4)"
            height="55px"
          >
            <DatePicker
              selected={endDate}
              onChange={(date) => setDates('end', date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              value={endDate || 'Date To'}
              className="fields-datepicker"
              dateFormat="dd/MM/yyyy"
            />
            <CalendarIcon/>
          </Flex>
        </Grid>
    </>
  );
};

export default DateInputs;
