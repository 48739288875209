import React, { useState } from "react";
import moment from "moment";
import {
  Box,
  Flex,
  Text,
  Button,
  Image,
  useToast,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { InfoOutlineIcon, WarningIcon } from "@chakra-ui/icons";
import Header from "./Header";
import cchub_logo from "../../assets/cchub.png";
import check from "../../assets/check.svg";
import { baseUrl, version } from "../url";

const inputStyle = {
  padding: 0,
  margin: 0,
  border: 0,
  width: "100%",
  outline: 0,
  background: "transparent",
};

const TextInput = ({
  label,
  placeholder,
  type = "text",
  error,
  onChange,
  right,
}) => {
  return (
    <Box
      height="62px"
      width="100%"
      borderWidth="0.5px"
      borderStyle="solid"
      borderColor={error ? "#DE3A11" : "#858585"}
      borderRadius="4px"
      p="10px 15px 12px"
    >
      <Text
        color="#858585"
        fontSize={14}
        fontWeight={400}
        lineHeight="143.16%"
        margin={0}
      >
        {label}
      </Text>
      {right ? (
        <Flex>
          <input
            placeholder={placeholder}
            type={type}
            style={inputStyle}
            onChange={onChange}
          />
          {right}
        </Flex>
      ) : (
        <input
          placeholder={placeholder}
          type={type}
          style={inputStyle}
          onChange={onChange}
        />
      )}
    </Box>
  );
};

const PrimaryButton = ({
  label,
  onClick,
  width = "100%",
  outline,
  isLoading,
}) => {
  return (
    <Button
      background={outline ? "transparent" : "#276DF7"}
      borderRadius="6px"
      border="2px solid #276DF7"
      width={width}
      height="55px"
      _active=""
      _hover=""
      _focus={{
        outline: "none",
      }}
      isLoading={isLoading}
      onClick={onClick}
    >
      <Text
        color={outline ? "#276DF7" : "#FFFFFF"}
        fontSize={18}
        fontWeight={500}
      >
        {label}
      </Text>
    </Button>
  );
};

const Verify = () => {
  const toast = useToast();
  const toastIdRef = React.useRef();

  const [verified, setVerified] = useState(null);
  const [caseNumber, setCaseNumber] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const reset = () => {
    setVerified(null);
    setCaseNumber(null);
    setPhoneNumber(null);
  };

  const verifyResult = () => {
    setIsLoading(true);
    let qs = [];
    qs[qs.length] = `caseNumber=${caseNumber}`;
    qs[qs.length] = `phone=${phoneNumber}`;

    fetch(`${baseUrl}/${version}/cases/verify/result?${qs.join("&")}`)
      .then((res) => {
        setIsLoading(false);
        return res.json();
      })
      .then((resJson) => {
        console.log(resJson);
        if (resJson.hasOwnProperty("error")) {
          setErrorMsg(resJson.message);
        } else {
          setVerified(resJson);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const displayToast = (status, description) => {
    toastIdRef.current = toast({
      position: "top",
      description,
      status,
      duration: 6000,
      isClosable: true,
    });
  };

  const colors = {
    negative: {
      color: "#00875A",
      background: "#E3FCEF",
    },
    positive: {
      color: "#DE1E1E",
      background: "rgba(222, 30, 30, 0.1)",
    },
    inconclusive: {
      color: "#FF991F",
      background: "rgba(255, 153, 31, 0.1)",
    },
  };

  const renderTooltip = () => {
    return (
      <Tooltip
        hasArrow
        label="Enter the patient number as seen in the result."
        maxWidth="225px"
        borderRadius="8px"
        padding="12px 20px"
        fontSize={14}
      >
        <InfoOutlineIcon />
      </Tooltip>
    );
  };

  return (
    <>
      <Header />
      <Flex
        minHeight="100vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
        background="#F9FBFF"
        p="80px 5%"
      >
        {!verified && !errorMsg && (
          <Box
            background="rgba(255, 255, 255, 0.8)"
            border="1px solid rgba(130, 127, 152, 0.15)"
            borderRadius="20px"
            padding={["30px", "60px"]}
            maxWidth="514px"
          >
            <Text
              color="#18131A"
              fontSize={[18, 24]}
              fontWeight={700}
              textAlign="center"
              mb="10px"
            >
              Verify a Test Result
            </Text>
            <Text
              color="#18131A"
              fontSize={16}
              fontWeight={500}
              textAlign="center"
              m="0 auto"
              mb="40px"
              width={["100%", "65%"]}
            >
              Enter the Case Number and Phone Number of a tester to verify their
              result.
            </Text>
            <Stack spacing="25px">
              <TextInput
                label="Case Number"
                onChange={(e) => setCaseNumber(e.target.value)}
              />
              <TextInput
                label="Patient Phone Number"
                onChange={(e) => setPhoneNumber(e.target.value)}
                right={renderTooltip()}
              />
            </Stack>
            <Flex mt="50px" mx="auto" justifyContent="center">
              <PrimaryButton
                label="Verify Result"
                width="200px"
                onClick={
                  caseNumber && phoneNumber
                    ? () => verifyResult()
                    : () =>
                        displayToast(
                          "error",
                          "Missing case number or phone number"
                        )
                }
                isLoading={isLoading}
              />
            </Flex>
          </Box>
        )}

        {verified && (
          <Box
            background="rgba(255, 255, 255, 0.8)"
            border="1px solid rgba(130, 127, 152, 0.15)"
            borderRadius="20px"
            padding={["30px", "60px"]}
            maxWidth="620px"
          >
            <Image src={check} boxSize="39px" display="block" m="0 auto" />
            <Text
              color="#18131A"
              fontSize={[18, 24]}
              fontWeight={700}
              textAlign="center"
              mb="10px"
              mt="15px"
            >
              Result Verified
            </Text>
            <Text
              color="#18131A"
              fontSize={16}
              fontWeight={500}
              textAlign="center"
              m="0 auto"
              mb="40px"
              width={["100%", "65%"]}
            >
              This result was issued by the Nigerian Institute of Medical
              Research (NIMR)
            </Text>
            <Flex justifyContent="space-between" flexWrap="wrap" mb="42px">
              <Box>
                <Text color="#091E42" fontSize={["13px","16px"]} fontWeight={500}>
                  Full Name
                </Text>
                <Text color="#344563" fontSize={["15px","18px"]} fontWeight={700}>
                  {verified?.last_name} {verified?.first_name}
                </Text>
              </Box>
              <Box>
                <Text
                  color="#091E42"
                  fontSize={["13px","16px"]}
                  fontWeight={500}
                  textAlign="right"
                >
                  Case Number
                </Text>
                <Text
                  color="#344563"
                  fontSize={["15px","18px"]}
                  fontWeight={700}
                  textAlign="right"
                >
                  {caseNumber}
                </Text>
              </Box>
            </Flex>
            <Flex justifyContent="space-between" flexWrap="wrap">
              <Box>
                <Text color="#091E42" fontSize={["13px","16px"]} fontWeight={500}>
                  Result Date
                </Text>
                <Text color="#344563" fontSize={["15px","18px"]} fontWeight={700}>
                  {moment(verified?.updated_at).format("MMMM D, YYYY")}
                </Text>
              </Box>
              <Box>
                <Text
                  color="#091E42"
                  fontSize={["13px","16px"]}
                  fontWeight={500}
                  textAlign="right"
                >
                  Result
                </Text>
                <Text
                  fontSize={["15px","18px"]}
                  fontWeight={700}
                  textAlign="right"
                  textTransform="capitalize"
                  color={colors[verified?.testResult].color}
                >
                  {verified?.testResult}
                </Text>
              </Box>
            </Flex>
            <Flex mt="50px" mx="auto" justifyContent="center">
              <PrimaryButton
                label="Done"
                width="200px"
                onClick={() => reset()}
              />
            </Flex>
          </Box>
        )}

        {errorMsg && (
          <Box
            background="rgba(255, 255, 255, 0.8)"
            border="1px solid rgba(130, 127, 152, 0.15)"
            borderRadius="20px"
            padding={["30px", "60px"]}
            maxWidth="514px"
          >
            {
              errorMsg[0] === "A" &&<>
              <Flex mb="18px" justifyContent="center">
                <WarningIcon boxSize="38px" color="#F1743A" />
              </Flex>
              <Text
                textAlign="center"
                color="#18131A"
                fontSize={24}
                fontWeight={700}
                mb="10px"
              >
                Result Not Available
              </Text>
              </>
            }
            {
              errorMsg[0] === "P"&&<>
              <Flex mb="18px" justifyContent="center">
                <WarningIcon boxSize="38px" color="#DE3A11" />
              </Flex>
              <Text
                textAlign="center"
                color="#18131A"
                fontSize={24}
                fontWeight={700}
                mb="10px"
              >
                Wrong Phone Number
              </Text>
              </>
            }
            {
              errorMsg[0] === "T" &&<>
              <Flex mb="18px" justifyContent="center">
                <WarningIcon boxSize="38px" color="#DE3A11" />
              </Flex>
              <Text
                textAlign="center"
                color="#18131A"
                fontSize={24}
                fontWeight={700}
                mb="10px"
              >
                Wrong ID
              </Text>
              </>
            }
            <Text
              textAlign="center"
              color="#18131A"
              fontSize={16}
              fontWeight={500}
              m="0 50px"
            >
              {errorMsg}
            </Text>
            <Flex mt="50px" mx="auto" justifyContent="center">
              <PrimaryButton
                label="Go Back"
                width="200px"
                onClick={() => setErrorMsg("")}
              />
            </Flex>
          </Box>
        )}

        <Flex alignItems="center" mt="50px">
          <Text color="#626262" fontSize={14} mr="6px">
            Powered by
          </Text>
          <Image src={cchub_logo} alt="" height="30px" />
        </Flex>
      </Flex>
    </>
  );
};

export default Verify;
