import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import IntlTelInput from 'react-intl-tel-input';
import Modal from '../modal/Modal';

const isProd = process.env.NODE_ENV === 'production';

export default class CaseContactSection extends Component {
  render() {
    const {
      contact_first_name,
      contact_last_name,
      contact_relation,
      transport_mode,
      contacts,
      showContactForm,
      handleChange,
      handleOptions,
      saveCaseContact,
      toggleContactForm,
      selectContact,
      incompleteFields,
      handleContactPhoneNumber,
      consent,
      consentModal,
      showConsentModal,
      hideConsentModal,
      captchaRef,
      sitekey,
      handleReCapcha,
      back,
      submit,
      error,
      noResponse,
      capchaValue
    } = this.props;
    return (
      <div className="form-sections">
        <h4 className="section-title mobile">Case Contact</h4>
        <p className="section-description mobile">
          Please provide the details of people you have been in close
          contact with over the past 2 weeks. (You can add as many as possible)
        </p>
        <div className="section-content">
        <h4 className="section-title desktop">Case Contact</h4>
        <p className="section-description desktop">
          Please provide the details of people you have been in close
          contact with over the past 2 weeks. (You can add as many as possible)
        </p>
          <label>
            Add Case Contact (If any)
            <div className="contact-list">
              {
                contacts.map((contact, index) => {
                  const { first_name } = contact;
                  return (
                    <div
                      className="contact" key={index} onClick={() => selectContact(index)}>
                      {first_name}
                      <i className="fa fa-edit" />
                    </div>
                  )
                })
              }
            </div>
            <div className="add-contact-btn" onClick={toggleContactForm}>
              {
                !showContactForm ?
                  <React.Fragment>
                    Add contact
                    <i className="fa fa-plus-circle fa-fw" />
                  </React.Fragment>
                  :
                  <React.Fragment>
                    cancel
                    <i className="fa fa-times-circle fa-fw cancel" />
                  </React.Fragment>
              }
            </div>
          </label>
          {
            showContactForm &&
            <div className="contact-details">
              <label>
                First Name
              <input
                  type="text"
                  name="contact_first_name"
                  placeholder="Enter First Name"
                  value={contact_first_name}
                  onChange={handleChange} />
              </label>
              <label>
                Last Name
              <input
                  type="text"
                  name="contact_last_name"
                  placeholder="Enter Last Name"
                  value={contact_last_name}
                  onChange={handleChange} />
              </label>
              <label>
                Relation to case
              <input
                  type="text"
                  name="contact_relation"
                  placeholder="mother, father, etc.."
                  value={contact_relation}
                  onChange={handleChange} />
              </label>
              <label>
                Phone Number
              <IntlTelInput
                  preferredCountries={['ng']}
                  separateDialCode={true}
                  useMobileFullscreenDropdown={false}
                  formatOnInit={false}
                  onPhoneNumberChange={handleContactPhoneNumber}
                />
              </label>
              <button className="save-contact-btn" onClick={saveCaseContact}>Save</button>
            </div>
          }
          <label className={incompleteFields && !transport_mode ? 'error' : ''}>
            <span className="star">*</span>For your safety, it's important to know how you are coming so we
            can make an arrangement to keep you safe. Are you coming with a car or by foot?
            <div className="input-options">
              <div className={`option ${transport_mode === "Private Vehicle" ? 'selected' : ''}`}
                onClick={() => handleOptions('transport_mode', 'Private Vehicle')}>
                Private Vehicle
              </div>
              <div className={`option ${transport_mode === "Public Transport" ? 'selected' : ''}`}
                onClick={() => handleOptions('transport_mode', 'Public Transport')}>
                Public Transport
              </div>
              <div className={`option ${transport_mode === "Foot" ? 'selected' : ''}`}
                onClick={() => handleOptions('transport_mode', 'Foot')}>
                Foot
              </div>
            </div>
          </label>
          <label className={incompleteFields && consent === null ? 'error' : ''}>
            <span className="star">*</span>Have you read and do you agree with our <span className="consent-link" onClick={showConsentModal}>Terms of Consent</span>?
            <div className="input-options two">
              <div className={`option ${consent === true ? 'selected' : ''}`}
                onClick={() => handleOptions('consent', true)}>
                Yes
              </div>
              <div className={`option ${consent === false ? 'selected' : ''}`}
                onClick={() => handleOptions('consent', false)}>
                No
              </div>
            </div>
          </label>
          {
            isProd &&
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={sitekey}
              onChange={handleReCapcha}
              size="compact"
            />
          }
          {
            incompleteFields && (isProd && !capchaValue) &&
            <p className="error-text">
              <i className="fa fa-exclamation-circle" />
              Check I'm not a robot
            </p>
          }
          {
            error &&
            <p className="error-text">
              <i className="fa fa-exclamation-circle" />
              {error}
            </p>
          }

          {
            noResponse &&
            <i className="fa fa-spinner fa-pulse fa-fw loader"></i>
          }
          <div className="button-section">
            <button onClick={back}>Back</button>
            <button className="right-button" onClick={submit}>Submit</button>
          </div>
        </div>
        <Modal show={consentModal}>
          <div className="consent-div">
            <i className="fa fa-times-circle close-icon" onClick={hideConsentModal}></i>
            <h4>Consent Agreement</h4>
            <p>The Nigerian Institute of Medical Research (NIMR) conducts important scientific research. This
                        research enables us to understand illnesses better, which in turn helps us in treating them better.</p>

            <p>We may have taken sample from you to find out which illness you are suffering from. It is
            possible that the leftover of those samples (possibly together with some medical data) may be used at
            a later point in time for scientific research. Only authorized researchers have access to your data and
            samples. Each of them is bound by professional confidentiality and has also signed a confidentiality
                            agreement.</p>

            <p>It is also possible that your medical data is used for imminent scientific research. In this case, your
            data will always be coded (your name will be replaced by a code, which makes you not directly
            identifiable) and whenever possible, your data will be completely anonymized. The link with your
            identity will be strictly separated. Your name or identifiable information will also never show up
                            in any publications arising from such research.</p>

            <p>Prior to the start of any research, be it with left-over samples or only coded medical data, we must
                            obtain approval from the NIMR Institutional Review Board (IRB).</p>

            <p>You have the right to refuse the later use of your samples and medical data in case you do not want this.
            In case you refuse the later use of your samples and medical data for scientific research, this will
                            not influence the care you receive at our laboratory now or in the future.</p>

            <p>Possibly, researchers may discover something during such later research that is potentially hazardous
            to your health or the health of others.  In this case, you will be informed about this out of moral
                            and legal obligation.</p>

            <p>If you still have questions about the later use of your body samples or data, feel free to ask the
                            Laboratory Director or the NIMR IRB secretary: <br />

                            Dr. Rosemary Audu, Laboratory Director (<a href="tel:08035017790">08035017790</a>)<br />
                            The Secretary, NIMR IRB (<a href="tel:08023513399">08023513399</a>)</p>

            <p>I have read and I now fully understand the purpose, risk and benefit of participating in future research.
                            I hereby freely give my consent for my left-over sample or data to be used for future research.</p>

            <div className="btn-div">
              <button onClick={hideConsentModal}>Close</button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
