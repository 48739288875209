import React, { Component } from 'react';
import moment from 'moment';

export default class ProfileDetails extends Component {
    arrToStr = (arr) => {
        if(arr && arr.length > 0){
            return arr.join(", ")
        }
        return "N/A"
    }
    render() {
        const userRole = localStorage.getItem('user_role');
        const {details} = this.props;
        const {
            case_number,
            first_name,
            last_name,
            title,
            gender,
            dob,
            marital_status,
            symptoms,
            nationality,
            countries_visited,
            home_address,
            phone_no,
            email,
            date_of_enrollment,
            employed_by_cases_management_facility,
            attended_mass_gathering,
            contact_with_overseas_traveller,
            contact_with_suspect,
            qualified_case,
            preExistingConditions,
            mode_of_transportation,
            bcg_vaccine,
            vaccinated,
            vaccineType,
            numberOfDoses,
            dateOfFirstDose,
            dateOfSecondDose,
            // testResult,
            // consent
        } = details;

        return (
            <div className="details">
                {
                    userRole==="super_admin" || userRole==="centre_admin"?
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <h4>Is candidate eligible?</h4>
                                </td>
                                <td>
                                    <h3 className={qualified_case==='YES'?'yes':'no'}>{qualified_case}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Case Number</h4>
                                </td>
                                <td>
                                    <h3>{case_number}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Title</h4>
                                </td>
                                <td>
                                    <h3>{title}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>First Name</h4>
                                </td>
                                <td>
                                    <h3>{first_name}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Last Name</h4>
                                </td>
                                <td>
                                    <h3>{last_name}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Sex</h4>
                                </td>
                                <td>
                                    <h3>{gender}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Date of Birth</h4>
                                </td>
                                <td>
                                    <h3>{moment(dob).format('DD/MM/YYYY')}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Marital Status</h4>
                                </td>
                                <td>
                                    <h3>{marital_status}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Symptoms</h4>
                                </td>
                                <td>
                                    <h3>{this.arrToStr(symptoms)}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Pre-existing Conditions</h4>
                                </td>
                                <td>
                                    <h3>{this.arrToStr(preExistingConditions)}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Nationality</h4>
                                </td>
                                <td>
                                    <h3>{nationality}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Countries traveled in the past month</h4>
                                </td>
                                <td>
                                    <h3>{this.arrToStr(countries_visited)}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Address</h4>
                                </td>
                                <td>
                                    <h3>{home_address}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Phone Number</h4>
                                </td>
                                <td>
                                    <h3>{phone_no}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Email</h4>
                                </td>
                                <td>
                                    <h3>{email? email : "N/A"}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Been in contact with a suspected/confirmed case</h4>
                                </td>
                                <td>
                                    <h3>{contact_with_suspect}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Been in an organisation(s) where a COVID-19 case was suspected/confirmed</h4>
                                </td>
                                <td>
                                    <h3>{employed_by_cases_management_facility}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Been to any mass gathering recently</h4>
                                </td>
                                <td>
                                    <h3>{attended_mass_gathering}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Had contact with travellers in the past 14 days</h4>
                                </td>
                                <td>
                                    <h3>{contact_with_overseas_traveller}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Taken BCG vaccine</h4>
                                </td>
                                <td>
                                    <h3>{bcg_vaccine}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Mode of transportation</h4>
                                </td>
                                <td>
                                    <h3>{mode_of_transportation}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Date Enrolled</h4>
                                </td>
                                <td>
                                    <h3>{moment(date_of_enrollment, 'x').format('DD/MM/YYYY')}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Vaccinated</h4>
                                </td>
                                <td>
                                    <h3>{vaccinated}</h3>
                                </td>
                            </tr>
                            {
                                vaccineType &&
                                <tr>
                                    <td>
                                        <h4>Vaccine Type</h4>
                                    </td>
                                    <td>
                                        <h3>{vaccineType}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                numberOfDoses &&
                                <tr>
                                    <td>
                                        <h4>Number of Doses</h4>
                                    </td>
                                    <td>
                                        <h3>{numberOfDoses}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                dateOfFirstDose &&
                                <tr>
                                    <td>
                                        <h4>Date of First Dose</h4>
                                    </td>
                                    <td>
                                        <h3>{moment(dateOfFirstDose, 'x').format('DD/MM/YYYY')}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                dateOfSecondDose &&
                                <tr>
                                    <td>
                                        <h4>Date of Second Dose</h4>
                                    </td>
                                    <td>
                                        <h3>{moment(dateOfSecondDose, 'x').format('DD/MM/YYYY')}</h3>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    :
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <h4>Case Number</h4>
                                </td>
                                <td>
                                    <h3>{case_number}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Title</h4>
                                </td>
                                <td>
                                    <h3>{title}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>First Name</h4>
                                </td>
                                <td>
                                    <h3>{first_name}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Last Name</h4>
                                </td>
                                <td>
                                    <h3>{last_name}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Sex</h4>
                                </td>
                                <td>
                                    <h3>{gender}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Date of Birth</h4>
                                </td>
                                <td>
                                    <h3>{moment(dob).format('DD/MM/YYYY')}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Phone Number</h4>
                                </td>
                                <td>
                                    <h3>{phone_no}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Email</h4>
                                </td>
                                <td>
                                    <h3>{email? email : "N/A"}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Mode of transportation</h4>
                                </td>
                                <td>
                                    <h3>{mode_of_transportation}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Vaccinated</h4>
                                </td>
                                <td>
                                    <h3>{vaccinated}</h3>
                                </td>
                            </tr>
                            {
                                vaccineType &&
                                <tr>
                                    <td>
                                        <h4>Vaccine Type</h4>
                                    </td>
                                    <td>
                                        <h3>{vaccineType}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                numberOfDoses &&
                                <tr>
                                    <td>
                                        <h4>Number of Doses</h4>
                                    </td>
                                    <td>
                                        <h3>{numberOfDoses}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                dateOfFirstDose &&
                                <tr>
                                    <td>
                                        <h4>Date of First Dose</h4>
                                    </td>
                                    <td>
                                        <h3>{moment(dateOfFirstDose, 'x').format('DD/MM/YYYY')}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                dateOfSecondDose &&
                                <tr>
                                    <td>
                                        <h4>Date of Second Dose</h4>
                                    </td>
                                    <td>
                                        <h3>{moment(dateOfSecondDose, 'x').format('DD/MM/YYYY')}</h3>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                }
                <div className="no-border"></div>
            </div>
        )
    }
}
