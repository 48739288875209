import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { apolloQuery, verifyToken, calculateTotalPages } from '../helpers';
import { toast } from 'react-toastify';
import SendInvite from '../SendInvite';
import Loader from '../Loader';

import 'react-toastify/dist/ReactToastify.css';

window.onload = function () {
    localStorage.removeItem('page_no')
}

toast.configure({
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false
});

class EnrolleesList extends Component {
    state = {
        cases: [],
        response: true,
        caseNumberSearch: '',
        inviteRequest: '',
        inviteView: false,
        skip: 0,
        perPage: 10,
        page: 1,
        total: 0,
        totalPages: 0,
    }

    async componentDidMount() {
        await verifyToken();
        const userRole = localStorage.getItem('user_role');
        const { match: { params } } = this.props;
        const { page, per_page } = params;
        this.setState({
            page: Number(page),
            perPage: Number(per_page),
            userRole,
        }, () => {
            this.getCases();
        })
    }

    componentWillUnmount() {
        this.setState({ isMounted: false })
    }

    getCases = async () => {
        this.setState({
            response: false,
        })
        const { caseNumberSearch, skip, perPage, } = this.state;
        let qs = [];
        if (caseNumberSearch) qs[qs.length] = `criteria:"${caseNumberSearch}"`;
        if (perPage) qs[qs.length] = `limit: ${perPage}`;
        if (skip) qs[qs.length] = `skip: ${skip}`;

        // const qs = `criteria:"${caseNumberSearch}"`;
        const query = `{
            cases(${qs.join(',')}){
                ...on Cases{
                    data{
                        _id,
                        case_number,
                        first_name,
                        last_name,
                        date_of_enrollment,
                        qualified_case,
                        phone_no,
                        email,
                        testResult,
                        testSubmission,
                        mode_of_transportation,
                        invitations{
                            date
                        }
                    }
                    meta{
                        ...on Pagination{
                            total
                        }
                    }
                }
            }
        }`
        const response = await apolloQuery(query);
        const { cases } = response;
        const { data, meta } = cases;
        const total = meta && meta.total;
        await this.setState({
            cases: data || [],
            response: true,
            total
        }, () => {
            this.scrollToTop()
            this.calculatePagination()
        })
    }

    handlePerPage = (e) => {
        this.setState({
            perPage: e.target.value,
            page: 1
        }, () => this.handlePage())
    }

    handlePage = () => {
        const { page, perPage } = this.state;
        this.loadPage(page, perPage);
        const skip = (page - 1) * perPage;
        this.setState({
            skip,
            cases: [],
            response: false,
        }, () => {
            this.getCases();
        })
    }

    setPage = (e) => {
        this.setState({
            page: Number(e.target.value)
        }, () => {
            this.handlePage();
        })
    }

    next = () => {
        const { page } = this.state;
        this.setState({
            page: page + 1
        }, () => {
            this.handlePage();
        })
    }

    prev = () => {
        const { page } = this.state;
        this.setState({
            page: page - 1
        }, () => {
            this.handlePage();
        })
    }

    calculatePagination = () => {
        const { perPage, total } = this.state;
        if (total) {
            const pagesObject = calculateTotalPages(total, perPage)
            const { totalPages } = pagesObject;
            this.setState({
                totalPages,
            })
        }
    }

    loadPage = (page, perPage) => {
        this.props.history.push({
            pathname: `/case-list/${page}/${perPage}`,
        })
    }

    sendInvite = async (id, date, time) => {
        this.setState({
            inviteRequest: true,
        })
        const formattedTime = moment(time, 'h:mmA').format('HH:mm:ss');
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const query = `mutation{
            caseInvite(
                id: "${id}",
                date: "${formattedDate} ${formattedTime}"
            ){
              status
            }
          }`
        const data = await apolloQuery(query);
        const caseInvite = data && data.caseInvite;
        const status = caseInvite && caseInvite.status;

        this.setState({
            inviteRequest: false
        })

        if (status === 200) {
            this.showSuccess('Invite sent');
            this.hideInviteView();
            this.getCases();
        }
    }

    showSuccess = (message) => toast.success(message);

    showInviteView = (id) => {
        this.setState({
            inviteView: true,
            inviteViewId: id
        })
    }

    hideInviteView = () => {
        this.setState({
            inviteView: false,
            inviteViewId: null
        })
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    // scrollToMyRef = () => {
    //     window.scrollTo({
    //         top: this.myRef.offsetTop,
    //         behavior: "smooth"
    //     })
    // }

    handleVerifyToken = () => {
        verifyToken();
    }

    handleSearch = (e) => {
        const { value } = e.target;
        this.setState({
            caseNumberSearch: value,
            cases: [],
        }, () => { this.getCases() })
    }

    viewProfile = (id) => {
        // localStorage.setItem('enrollee_id', id)
        this.props.history.push({
            pathname: `/case-profile/${id}`,
        })
    }

    render() {
        const {
            cases,
            response,
            caseNumberSearch,
            inviteRequest,
            inviteView,
            total,
            totalPages,
            page,
            perPage,
            userRole,
            inviteViewId
        } = this.state;
        let pageOptions = [];
        for (let i = 1; i <= totalPages; i++) {
            pageOptions = [...pageOptions, <option key={i}>{i}</option>]
        }
        return (
            <React.Fragment>
                <div className="search-section">
                    <h4>Search Case</h4>
                    <div className="input-div">
                        <input type="text" onChange={this.handleSearch} placeholder="Search case number, first name or last name" onFocus={this.handleVerifyToken} />
                        <i className="fa fa-search" />
                    </div>
                </div>
                <div className="case-list">
                    <div className="title">
                        {
                            caseNumberSearch.length > 0 ?
                                <h4>Search result({cases.length})</h4> :
                                <h4>Cases({total})</h4>
                        }
                    </div>
                    <div className="list">
                        {
                            cases &&
                            cases.length > 0 &&

                            <table>
                                <tbody>
                                    <tr>
                                        {/* <th></th> */}
                                        <th>
                                            <div className="first">
                                                Clinic No
                                            </div>
                                        </th>
                                        <th>
                                            <div className="first">
                                                First Name
                                            </div>
                                        </th>
                                        <th>
                                            <div className="first">
                                                Last Name
                                            </div>
                                        </th>
                                        <th>
                                            <div className="first">
                                                Eligibility
                                            </div>
                                        </th>
                                        <th>
                                            <div className="first">
                                                Date Enrolled
                                            </div>
                                        </th>
                                        <th>
                                            <div className="first">
                                                Status
                                            </div>
                                        </th>
                                        <th></th>
                                    </tr>
                                    {
                                        cases && cases.map((entry, index) => {
                                            const {
                                                _id,
                                                case_number,
                                                first_name,
                                                last_name,
                                                date_of_enrollment,
                                                qualified_case,
                                                phone_no,
                                                email,
                                                testResult,
                                                testSubmission,
                                                invitations,
                                                mode_of_transportation,
                                            } = entry;
                                            let status = "Request Submitted";
                                            if (invitations) status = "Invite Sent";
                                            if (testSubmission === 'COLLECTED') status = "Sample Collected";
                                            if (testResult) status = "Test Completed";
                                            let inviteButton;

                                            if (!invitations) {
                                                inviteButton = (
                                                    <button className="send-invite" onClick={() => this.showInviteView(_id)}>
                                                        <i className={inviteRequest ? "fa fa-spinner fa-pulse fa-fw" : "fa fa-paper-plane"} />
                                                        Send invite
                                                    </button>
                                                )
                                            }

                                            if (invitations && invitations.length < 5) {
                                                inviteButton = (
                                                    <button className="send-invite" onClick={() => this.showInviteView(_id)}>
                                                        <i className={inviteRequest ? "fa fa-spinner fa-pulse fa-fw" : "fa fa-paper-plane"} />
                                                        Resend invite ({5 - invitations.length})
                                                    </button>
                                                )
                                            }

                                            if (invitations && invitations.length === 5) {
                                                inviteButton = (
                                                    <div className="invite-sent">
                                                        <i className="fa fa-check" />
                                                        invite sent
                                                    </div>
                                                )
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="table-item">
                                                            {case_number}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-item">
                                                            {first_name}
                                                        </div>
                                                        {
                                                            <div className="info-popup">
                                                                {
                                                                    inviteView && inviteViewId === _id &&
                                                                    <SendInvite
                                                                        close={this.hideInviteView}
                                                                        sendInvite={this.sendInvite}
                                                                        id={_id}
                                                                        inviteRequest={inviteRequest}
                                                                    />
                                                                }
                                                                {
                                                                    !inviteView &&
                                                                    <div className="details">
                                                                        <div>
                                                                            <p>Case number: <span>{case_number}</span></p>
                                                                            <p>Phone number: <span>{phone_no}</span></p>
                                                                            <p>Email address: <span>{email}</span></p>
                                                                            <p>Eligibility: <span>{qualified_case}</span></p>
                                                                            <p>Mode of Transportation: <span>{mode_of_transportation}</span></p>
                                                                        </div>
                                                                        {(userRole === "super_admin" || userRole === "centre_admin") && inviteButton}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="table-item">
                                                            {last_name}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-item">
                                                            <span className={qualified_case === 'YES' ? 'yes' : 'no'}>{qualified_case}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-item">
                                                            {moment(date_of_enrollment, 'x').format('DD/MM/YYYY')}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-item">
                                                            "{status}"
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="view-button" onClick={() => this.viewProfile(_id)}>View</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        }

                        {
                            response &&
                            (cases === null || cases.length === 0) &&
                            // caseNumberSearch &&
                            <div className="case-list empty">
                                <h4>No case matches the search parameter.</h4>
                            </div>
                        }

                        {
                            !response && <Loader />
                        }

                        <div className="pagination-div">
                            <div className="entries">
                                Entries per page:
                                    <select value={perPage} onChange={this.handlePerPage}>
                                    <option>20</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                            </div>
                            <div className="pagination">
                                <button onClick={this.prev} disabled={page <= 1 ? true : false}>Previous</button>
                                {/* <input type="number" value={page} onChange={this.onChange} /> */}
                                <select value={page} onChange={this.setPage}>
                                    {pageOptions}
                                </select>
                                <p>of</p>
                                <div>{totalPages}</div>
                                <button onClick={this.next} disabled={page >= totalPages ? true : false}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(EnrolleesList);
