import React from "react";
import Header from "../Header";
import { NavLink, withRouter } from "react-router-dom";
import { Box, Flex, Text } from "@chakra-ui/react";
import { DetailsIcon, PasswordIcon, RightArrow } from "../Icons";

import "./style.scss";

const AccountLayout = ({ children }) => {
  return (
    <Box mt="65px" py="78px" px="210px" height="100vh" background="#EFF2F6">
      <Header />
      <Flex maxW="980px" mx="auto" justifyContent="space-between">
        <Box width="30%">
          {/* <Flex alignItems="center" py="32px" h="75px" borderRadius="10px 10px 0px 0px"></Flex> */}
          <NavLink
            className="side-menu-link"
            activeClassName="side-menu-link-active"
            to={{
              pathname: "/account/details",
              state: { id: "ID HERE" },
            }}
          >
            <DetailsIcon customClass="svgColor" />
            <Text marginLeft="18px">My Details</Text>
            <RightArrow customClass="right-arrow" />
          </NavLink>
          <NavLink
            className="side-menu-link"
            activeClassName="side-menu-link-active"
            to={{
              pathname: "/account/password",
              state: { id: "ID HERE" },
            }}
          >
            <PasswordIcon customClass="svgColor" />
            <Text marginLeft="18px">Password Settings</Text>
            <RightArrow customClass="right-arrow" />
          </NavLink>
        </Box>
        <Box width="65%">{children}</Box>
      </Flex>
    </Box>
  );
};

export default withRouter(AccountLayout);
