import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Form from './components/form/Form';
import Dasboard from './components/dashboard/Dasboard';
import Appointments from './components/appointments/Appointments';
import CaseProfiles from './components/case-profiles/CaseProfiles';
import Login from './components/authentication/Login';
import Details from './components/account/Details';
import PassWordSettings from './components/account/PassWordSettings';
import ForgotPassword from './components/authentication/ForgotPassword';
import ResetPassword from './components/authentication/ResetPassword';
import Overview from './components/overview/Overview';
import Verify from  './components/verify/Verify';
import { ProtectedRoute } from './components/authentication/Authentication';
import { ChakraProvider } from "@chakra-ui/react";

import './App.css';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Form} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route exact path="/case-list" render={() => (
            <Redirect to="/case-list/1/20"/>
          )}/>
          <Route path="/verify" component={Verify} />
          <ProtectedRoute exact path='/case-list/:page/:per_page' component={Dasboard} />
          <ProtectedRoute exact path="/case-profile/:id" component={CaseProfiles} />
          <ProtectedRoute exact path="/appointments" component={Appointments} />
          <ProtectedRoute exact path="/overview" component={Overview} />
          <ProtectedRoute exact path="/account/details" component={Details} />
          <ProtectedRoute exact path="/account/password" component={PassWordSettings} />
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;