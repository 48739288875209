import React, { Component } from 'react';

export default class PendingCollection extends Component {
    render() {
        const {
            hide,
            updateCollectionStatus,
            id,
            request
        } = this.props;
        return (
            <div className="collection-modal">
                <i className="fa fa-exclamation-circle" />
                <p>Are you sure want to confirm collection?</p>
                <div className="btn-div">
                    <button className="btn-outline" onClick={hide}>No</button>
                    <button onClick={() => updateCollectionStatus(id)}>Yes</button>
                </div>
                {
                    request && <i className="fa fa-spinner fa-pulse fa-fw" />
                }
            </div>
        )
    }
}