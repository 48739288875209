import React from "react";
import { Box, Flex, Text, Image } from "@chakra-ui/react";

const MetricCard = ({ title, number, icon }) => {
  return (
    <Flex
      w="100%"
      borderRadius="8px"
      py="18px"
      px="30px"
      bg="#FFFFFF"
      justifyContent="space-between"
      alignItems="center"
      border="1px solid rgba(223, 224, 235, 0.5)"
    >
      <Box>
        <Text color="rgba(22, 21, 49, 0.6)" fontSize={14}>
          {title}
        </Text>
        <Text color="rgba(22, 21, 49, 0.8)" fontSize={26} fontWeight={700}>
          {number}
        </Text>
      </Box>
      <Image
        boxSize="40px"
        objectFit="cover"
        src={icon}
        alt={title + " Icon"}
      />
    </Flex>
  );
};

export default MetricCard;
