import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from "recharts";

import "./style.scss";

const TransportChart = ({ data }) => {
  const colors = [
    '#F36B50',
    '#FDD587',
    '#3E81FF',
    '#69C098'
  ]
  const total = data && data.reduce((a,b) => ({cases: a.cases + b.cases}), {cases: 0});
  return (
    <Box
      bg="#FFFFFF"
      border="1px solid rgba(223, 224, 235, 0.7)"
      borderRadius="8px"
    >
      <Flex
        px="30px"
        py="22px"
        borderBottom="1.5px solid rgba(223, 224, 235, 0.5)"
        // justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Text color="rgba(37, 39, 51, 0.85)" fontSize={16}>
          Mode of Transportation Applicants Indicated
        </Text>
      </Flex>
      <Flex p="0 40px 20px 20px">
        <ResponsiveContainer width="40%" height={250}>
          <PieChart width={250} height={250}>
            <Pie
              data={data || []}
              cx={100}
              cy={120}
              labelLine={false}
              outerRadius={95}
              innerRadius={70}
              fill="#8884d8"
              dataKey="cases"
            >
              {data && data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index]} /> )}
              <Label
                value={total?.cases || 0}
                position="centerBottom"
                fill="#252733"
                fontSize="20px"
                fontWeight={700}
              />
              <Label
                value="Applicants"
                position="centerTop"
                fill="rgba(37, 39, 51, 0.8)"
                fontSize="13px"
                className="label-bottom"
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <Flex direction="column" justifyContent="center" width="60%" pl="60px" pr="20px">
          {
            data && data.map((entry, index) => (
              <Flex alignItems="center" key={`key-${index}`} mb="20px" _last={{ marginBottom: 0 }}>
                <Box backgroundColor={colors[index]} h="10px" w="10px" mr="15px"></Box>
                <Text color="rgba(37, 39, 51, 0.85)" fontSize={14} fontWeight={500}>{entry.type}</Text>
                <Text color="rgba(37, 39, 51, 0.9)" fontSize={16} fontWeight={500} ml="auto">{entry.cases}</Text>
              </Flex>
            ))
          }
        </Flex>
      </Flex>
    </Box>
  );
};

export default TransportChart;
