import React, { useState, useEffect } from "react";
import AuthLayout from "./AuthLayout";
import { useLocation, useHistory } from "react-router-dom";
import { baseUrl, version } from "../url";
import { useToast } from "@chakra-ui/react";
import * as QueryString from "query-string";

const ResetPassword = () => {
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();

  const passwords = {
    newPassword: '',
    confirmPassword: '',
  }

  const [values, setValues] = useState(passwords);

  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const params = QueryString.parse(location.search);
    const { token } = params;
    setToken(token);
    checkPasswords();
  }, [location, values]);

  const checkPasswords = () => {
    const { newPassword, confirmPassword } = values;
    if(confirmPassword && (newPassword !== confirmPassword)) setPasswordError("Passwords do not match")
    else setPasswordError("")
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    })
  }

  const resetPassword = (e) => {
    e.preventDefault();

    const access_token = `Bearer ${token}`;

    // const bodyObj = {
    //   newPassword,
    //   con
    // };

    setIsLoading(true);

    fetch(`${baseUrl}/${version}/admin/password/reset`, {
      method: "PUt",
      headers: {
        Authorization: access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        credentials: "include",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setIsLoading(false);
        console.log(responseJson);
        if (responseJson.status === 200) {
          history.push('/login');
          toast({
            position: "top",
            title: "Success",
            description: "A new password has been created, proceed to login",
            status: "success",
            duration: 8000,
            isClosable: true,
          });
        }
        if (responseJson.hasOwnProperty("error")) {
          const { error } = responseJson;
          const desc =
            typeof error === "string" ? error : "Unable to reset your password.";
          toast({
            position: "top",
            title: "An error occurred.",
            description: desc,
            status: "error",
            duration: 6500,
            isClosable: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <AuthLayout title="Set New Password">
      <form>
        {/* <div className={`input-div ${passwordError ? "error" : ""}`}> */}
        <div className={`input-div`}>
          <label>New Password</label>
          <input
            type="password"
            name="newPassword"
            onChange={handleChange}
            value={values.password}
          />
          {/* <span>{passwordError}</span> */}
        </div>
        <div className={`input-div ${passwordError ? "error" : ""}`}>
        {/* <div className={`input-div`}> */}
          <label>Confirm New Password</label>
          <input
            type="password"
            name="confirmPassword"
            onChange={handleChange}
            value={values.confirmPassword}
          />
          <span>{passwordError}</span>
        </div>
        <button onClick={resetPassword} disabled={(passwordError || (!values.confirmPassword && !values.newPassword))}>
          Reset Password
          {isLoading && <i className="fa fa-spinner fa-pulse fa-fw"></i>}
        </button>
        {/* <p>{error}</p> */}
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
