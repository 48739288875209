import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import AuthLayout from "./AuthLayout";
import { baseUrl, version } from "../url";
import { useToast } from "@chakra-ui/react";

const ForgotPassword = () => {
  const toast = useToast();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const token = `Bearer ${localStorage.getItem("access_token")}`;

    const bodyObj = {
      email,
    };

    setIsLoading(true);

    fetch(`${baseUrl}/${version}/admin/password/forgot`, {
      method: "POST",
      headers: {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        credentials: "include",
      },
      body: JSON.stringify(bodyObj),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setIsLoading(false);
        if (responseJson.status === 201) {
          setEmailSent(true);
        }
        if (responseJson.hasOwnProperty("error")) {
          const { error } = responseJson;
          const desc =
            typeof error === "string" ? error : "Unable to update password.";
          toast({
            position: "top",
            title: "An error occurred.",
            description: desc,
            status: "error",
            duration: 6500,
            isClosable: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const goToLogin = () => {
    history.push('/login');
  }

  return (
    <AuthLayout title="Reset Password">
      <form>
        {!emailSent && (
          <React.Fragment>
            <div className={`input-div`}>
              {/* <div className={`input-div ${usernameError ? "error" : ""}`}> */}
              <label>Email Address</label>
              <input
                type="email"
                name="username"
                onChange={handleChange}
                value={email}
              />
              {/* <span>{usernameError}</span> */}
            </div>
            <button onClick={sendEmail} disabled={!email}>
              Reset Password
              {isLoading && <i className="fa fa-spinner fa-pulse fa-fw"></i>}
            </button>
            {/* <p>{error}</p> */}
          </React.Fragment>
        )}
        {emailSent && (
          <React.Fragment>
            <h3 className="reset-text">A link to reset your password has been sent to your email</h3>
            <button onClick={goToLogin}>Ok</button>
          </React.Fragment>
        )}
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
