
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { apolloQuery } from './helpers';

import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false
});

export default class SendInvite extends Component {
    state = {
        timeSlots: [
            {time: "10:00AM"},
            {time: "10:30AM"},
            {time: "11:00AM"},
            {time: "11:30AM"},
            {time: "12:00PM"},
            {time: "12:30PM"},
            {time: "1:00PM"},
            {time: "1:30PM"},
            {time: "2:00PM"},
            {time: "2:30PM"},
            {time: "3:00PM"},
        ],
        selectedTime: '',
        selectedDate: '',
        inviteNo: null,
    }

    componentDidMount(){
        const tomorrow = moment().add(1,'d').toDate('DD-MM-YYYY');
        this.setState({
            selectedDate: tomorrow
        }, () => this.fetchSlotsData())
    }

    fetchSlotsData = async () => {
        const { selectedDate, selectedTime } = this.state;
        this.setState({inviteNo: null})
        const query = `{
            testSlots(date: "${moment(selectedDate).format('DD-MM-YYYY')}"){
                status
                meta{
                    type
                }
                ...on TestSlots{
                    data{
                        slots,
                        timezone
                    }
                }
            }
          }`
        const response = await apolloQuery(query);
        const { testSlots } = response;
        const { data, status } = testSlots;
        const { slots, timezone } = data;
        if (status === 200){
            this.setState({
                slots,
                timezone
            },() => {
                if(selectedTime) this.checkSlot(selectedTime);
            })
        } else {
            this.showError("Something went wrong fetching time slots")
        }
    }

    checkSlot = (time) => {
        const { slots, timezone } = this.state;
        Object.keys(slots).forEach((key) => {
            const timezoneTime = moment.tz(key, 'HH:mm', false, 'UTC').tz(timezone).format('h:mmA')
            if(timezoneTime===time) this.setState({inviteNo: slots[key]});
        })
    }

    selectTime = (time) => {
        this.checkSlot(time);
        this.setState({
            selectedTime: time,
            timeError: '',
        })
    }

    selectDate = (date) => {
        this.setState({
            selectedDate: date,
        }, () => this.fetchSlotsData())
    }

    sendInvite = () => {
        const { selectedDate, selectedTime } = this.state;
        const { id, sendInvite } = this.props;
        if(!selectedTime){
            this.setState({
                timeError: 'Please pick a time',
            })
        } else {
            sendInvite(id, selectedDate, selectedTime);
        }
    }

    showError = (message) => toast.error(message);

    render(){
        const {timeSlots, selectedTime, selectedDate, timeError, inviteNo, slots} = this.state;
        const {close, inviteRequest} = this.props;
        const inviteText = inviteNo === 0? 'no invite sent': inviteNo === 1? '1 invite sent': `${inviteNo} invites sent`;
        return(
            <div className="invite-modal">
                <h4>Set Schedule Date and Time</h4>
                <h5>Choose date and time for invitee’s visit</h5>
                <div className="invite-section">
                    {
                        slots &&
                        <div className="time-section">
                            <p>Select time</p>
                            <div className="time-slots">
                                {
                                    timeSlots.map(slot => {
                                        const { time } = slot;
                                        return(
                                            <div className={selectedTime===time?"slot selected":"slot"} key={time} onClick={() => this.selectTime(time)}>
                                                {time}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                    <div className="date-selected">
                        <div className="date-input-div">
                            <p>Select Date (optional)</p>
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                placeholderText="dd-mm-yy"
                                popperClassName="calendar"
                                className="date-input"
                                popperPlacement="left"
                                showPopperArrow={false}
                                minDate={new Date()}
                                selected={selectedDate}
                                onChange={date => this.selectDate(date)}
                            />
                        </div>
                        <div className="appointment-details">
                            {inviteNo!==null && <span className="invite-no">{inviteText}</span>}
                            <p>Invitation for:</p>
                            <h4>{moment(selectedDate).format('MMM DD')}{selectedTime && <span>, {selectedTime}</span>}</h4>
                            {timeError && <p className="error-text">{timeError}</p>}
                            <div className="button-div">
                                <button onClick={close}>Cancel</button>
                                <button className="send" onClick={this.sendInvite}>
                                    {inviteRequest && <i className="fa fa-spinner fa-pulse fa-fw"/>}
                                    Send invite
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}