import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Box,
  Flex,
  Text,
  Button,
  Grid,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import Header from "../Header";
import MetricCard from "./MetricCard";
import SubmissionsGraph from "./SubmissionsGraph";
import AgeGroupChart from "./AgeGroupChart";
import MaritalChart from "./MaritalChart";
import BCGChart from "./BCGChart";
import GenderChart from "./GenderChart";
import TransportChart from "./TransportChart";
import DataExport from "./dataExport/DataExport";
import {
  apolloQuery,
  // verifyToken,
  // calculateTotalPages
} from "../helpers";

import total_icon from "../../assets/overview-total.svg";
import invitees_icon from "../../assets/overview-invitees.svg";
import tested_icon from "../../assets/overview-tested.svg";

// import './style.scss';

const Overview = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialDates = {
    start: "",
    end: "",
  };

  const [dates, setDates] = useState(initialDates);
  const [showDatePicker, setShoWDatePicker] = useState(false);
  const [submissionChartData, setSubmissionChartData] = useState([]);
  const [metricData, setMetricData] = useState({});
  const [stats, setStats] = useState({});
  const [ageGroupData, setAgeGroupData] = useState([]);
  const [maritalData, setMaritalData] = useState([]);
  const [bcgData, setBcgData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [transportData, setTransportData] = useState([]);

  useEffect(() => {
    fetchSubmissionData();
    fetchAgeGroupData();
    fetchMaritalData();
    fetchBCGData();
    fetchGenderData();
    fetchTransportData();
  }, []);

  const fetchSubmissionData = async () => {
    let qs = [];
    const { start, end } = dates;
    if (start && end)
      qs[qs.length] = `from: "${moment(start).format(
        "YYYY-MM-DD"
      )}", to: "${moment(end).format("YYYY-MM-DD")}"`;

    const query = `{
        ${qs.length > 0 ? `caseGraph(${qs.join(",")})` : "caseGraph"}{
            status
            ...on CaseGraph{ 
              analysis
              graph
              stats
            }
        }
    }`;
    const response = await apolloQuery(query);
    const { caseGraph } = response;
    const { analysis, graph, stats } = caseGraph;
    setMetricData(analysis);
    setStats(stats);
    setSubmissionChartData(graph);
  };

  const fetchAgeGroupData = async () => {
    const query = `{
      ageGraph{
          status
          ...on AgeGraph{ 
            graph
          }
        }
    }`;
    const response = await apolloQuery(query);
    const {
      ageGraph: { graph },
    } = response;
    setAgeGroupData(graph);
  };

  const fetchMaritalData = async () => {
    const query = `{
      maritalGraph{
          status
          ...on MaritalGraph{ 
            graph
          }
        }
    }`;
    const response = await apolloQuery(query);
    const {
      maritalGraph: { graph },
    } = response;
    setMaritalData(graph);
  };

  const fetchBCGData = async () => {
    const query = `{
      bcgGraph{
          status
          ...on BcgGraph{ 
            graph
          }
        }
    }`;
    const response = await apolloQuery(query);
    const {
      bcgGraph: { graph },
    } = response;
    setBcgData(graph);
  };

  const fetchGenderData = async () => {
    const query = `{
      genderGraph{
          status
          ...on GenderGraph{ 
            graph
          }
        }
    }`;
    const response = await apolloQuery(query);
    const {
      genderGraph: { graph },
    } = response;
    setGenderData(graph);
  };

  const fetchTransportData = async () => {
    const query = `{
      transportGraph{
          status
          ...on TransportGraph{ 
            graph
          }
        }
    }`;
    const response = await apolloQuery(query);
    const {
      transportGraph: { graph },
    } = response;
    setTransportData(graph);
  };

  const selectDates = (dates) => {
    const [start, end] = dates;
    setDates({
      start,
      end,
    });
    if (start && end) setShoWDatePicker(!showDatePicker);
  };

  return (
    <Box mt="65px" py="78px" px="130px" minHeight="100vh" background="#EFF2F6">
      <Header page="overview" />
      <Box maxW="1180px" mx="auto">
        <Flex justifyContent="space-between" alignItems="center" mb="43px">
          <Text color="#252733" fontSize={20} fontWeight={500}>
            Overview
          </Text>
          <Button
            background="#276DF7"
            h="45px"
            w="143px"
            _hover=""
            color="#ffffff"
            onClick={onOpen}
          >
            <Text fontWeight="bold" fontSize="16px">
              Export Data
            </Text>
          </Button>
        </Flex>
        <Grid templateColumns="repeat(3, 1fr)" gap="20px" mb="73px">
          <MetricCard
            title="Applicants"
            number={metricData?.requests}
            icon={total_icon}
          />
          <MetricCard
            title="Number of Invitees"
            number={metricData?.invitees}
            icon={invitees_icon}
          />
          <MetricCard
            title="Samples Tested"
            number={metricData?.results}
            icon={tested_icon}
          />
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)" gap="20px" mb="50px">
          <Box gridColumn="1 / span 2">
            <SubmissionsGraph
              dates={dates}
              selectDates={selectDates}
              showDatePicker={showDatePicker}
              setShoWDatePicker={setShoWDatePicker}
              data={submissionChartData}
            />
          </Box>
          <Box>
            <Stack
              spacing="25px"
              p="30px"
              bg="#FFFFFF"
              border="1px solid rgba(223, 224, 235, 0.7)"
              borderRadius="8px"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Text
                  color="rgba(37, 39, 51, 0.8)"
                  fontSize={15}
                  fontWeight={15}
                  width="60%"
                >
                  Days form has been Online
                </Text>
                <Text color="#276DF7" fontSize={15} fontWeight={15}>
                  {stats?.onlineDays}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text
                  color="rgba(37, 39, 51, 0.8)"
                  fontSize={15}
                  fontWeight={15}
                  width="60%"
                >
                  Applicant Eligible for Testing
                </Text>
                <Text color="#276DF7" fontSize={15} fontWeight={15}>
                  {stats?.eligible}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text
                  color="rgba(37, 39, 51, 0.8)"
                  fontSize={15}
                  fontWeight={15}
                  width="60%"
                >
                  Eligible Applicants Invited for Testing
                </Text>
                <Text color="#276DF7" fontSize={15} fontWeight={15}>
                  {stats?.invited_eligible}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text
                  color="rgba(37, 39, 51, 0.8)"
                  fontSize={15}
                  fontWeight={15}
                  width="60%"
                >
                  Invited Applicants who have been tested
                </Text>
                <Text color="#276DF7" fontSize={15} fontWeight={15}>
                  {stats?.invited_tested}
                </Text>
              </Flex>
            </Stack>
          </Box>
        </Grid>
        <AgeGroupChart data={ageGroupData} />
        <Grid templateColumns="repeat(2, 1fr)" gap="20px" my="50px">
          <MaritalChart data={maritalData} />
          <BCGChart data={bcgData} />
        </Grid>
        <Grid templateColumns="repeat(2, 1fr)" gap="20px" my="50px">
          <GenderChart data={genderData} />
          <TransportChart data={transportData} />
        </Grid>
      </Box>
      {isOpen && <DataExport isOpen={isOpen} onClose={onClose} />}
    </Box>
  );
};

export default Overview;
