import React from 'react';


const Loader = () => {
    return(
        <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            padding: "50px",
        }}>
            <i style={{
                fontSize: "36px",
                color: "#6C63FF"
            }}
                className="fa fa-spinner fa-pulse fa-fw"></i>
        </div>
    )
}

export default Loader
