import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { baseUrl, version } from '../url';

import 'react-toastify/dist/ReactToastify.css';

toast.configure({
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false
});

export default class TestResult extends Component {
    state = {
        result: '',
        request: false
    }
    handleSelect = (e) => {
        this.setState({
            result: e.target.value,
            resultError: ''
        })
    }

    showSuccess = (message) => toast.success(message);

    updateTestResult = (id) => {
        const { result } = this.state;
        if (!result) {
            this.setState({
                resultError: "Please select a valid result"
            })
        }
        if (result) {
            this.setState({
                request: true,
            })
            const token = `Bearer ${localStorage.getItem('access_token')}`;
            fetch(`${baseUrl}/${version}/cases/test/result/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'credentials': 'include',
                    'Authorization': token
                },
                body: JSON.stringify({
                    result
                })
            })
                .then(response => {
                    this.setState({
                        request: false
                    })
                    return response.json()
                })
                .then(responseJson => {
                    if (responseJson.status === 200 || responseJson.status === 201 || responseJson.hasOwnProperty('testResult')) {
                        this.showSuccess("Test has been updated successfully")
                        this.props.hide();
                        this.props.getCase(id)
                    }
                })
        }
    }
    render() {
        const {
            hide,
            id
        } = this.props;
        const { request, resultError } = this.state;
        return (
            <div className="collection-modal">
                <i className="fa fa-exclamation-circle" />
                <p>Update case result.</p>
                <select onChange={this.handleSelect}>
                    <option>Select result</option>
                    <option value="positive">Positive</option>
                    <option value="negative">Negative</option>
                    <option value="inconclusive">Inconclusive</option>
                </select>
                <div className="btn-div">
                    <button className="btn-outline" onClick={hide}>Cancel</button>
                    <button onClick={() => this.updateTestResult(id)}>Update</button>
                </div>
                {
                    request && <i className="fa fa-spinner fa-pulse fa-fw" />
                }
                {
                    resultError && <p className="error-text">{resultError}</p>
                }
            </div>
        )
    }
}