import React from "react";
import {
  Flex,
  Grid,
  Text,
  ModalFooter,
  ModalBody,
  Box
} from "@chakra-ui/react";

import { ButtonComponent } from './Button'

import { StepCheckIcon, StepUncheckIcon } from '../../Icons';

const Start = ({ steps, selectStep, setCurrentStep }) => {
  return (
    <>
      <ModalBody p="50px">
        <Text
          color="rgba(37, 39, 51, 0.85)"
          fontSize={18}
          fontWeight={500}
          mb="35px"
        >
          Select the data type you want to export
        </Text>
        <Grid templateColumns="repeat(3, 1fr)" gap="30px">
          {steps.map((step) => {
            return (
              <Flex
                key={step.name}
                direction="column"
                justifyContent="center"
                alignItems="center"
                borderRadius="8px"
                background={
                  step.selected
                    ? "1px solid rgba(223, 224, 235, 0.7)"
                    : "#FFFFFF"
                }
                border={
                  step.selected
                    ? "1px solid #276DF7"
                    : "1px solid rgba(159, 162, 180, 0.6)"
                }
                height="100px"
                onClick={() => selectStep(step.name)}
                cursor="pointer"
                position="relative"
              >
                <Box height="20px" width="20px" position="absolute" backgroundColor="red" right="8px" top="8px">
                  {
                    step.selected ? <StepCheckIcon /> : <StepUncheckIcon />
                  }
                </Box>
                <step.icon fill={step.selected ? "#276DF7" : "#9FA2B4"} />
                <Text
                  mt="10px"
                  fontSize={17}
                  fontWeight={500}
                  color={step.selected ? "#276DF7" : "#A9ABBB"}
                >
                  {step.name}
                </Text>
              </Flex>
            );
          })}
        </Grid>
      </ModalBody>

      <ModalFooter pb="50px">
        <ButtonComponent buttonText="Next" triggerAction={setCurrentStep} />
      </ModalFooter>
    </>
  );
};

export default Start;
