import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { baseUrl, version } from "../../url";

import {
  CasesIcon,
  TracingIcon,
  AppointmentsIcon,
  BackArrowIcon,
  UploadingIcon
} from "../../Icons";
import useDidMountEffect from "./DidMountHook";
import Start from "./Start";
import CasesFields from "./CasesFields";
import TracingFields from "./TracingFields";
import AppointmentFields from "./AppointmentFields";
import { ButtonComponent } from "./Button";

const DataExport = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [steps, setSteps] = useState([
    {
      name: "Cases",
      selected: false,
      icon: CasesIcon,
      queries: [],
      startDate: "",
      endDate: "",
    },
    {
      name: "Contact Tracing",
      selected: false,
      icon: TracingIcon,
      queries: [],
      startDate: "",
      endDate: "",
    },
    {
      name: "Appointments",
      selected: false,
      icon: AppointmentsIcon,
      queries: [],
      startDate: "",
      endDate: "",
    },
  ]);

  const [currentStep, setCurrentStep] = useState("start");
  const [formList, setFormList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const selectStep = (name) => {
    let getIndex = steps.findIndex((step) => step.name === name);
    let casesIndex = steps.findIndex((step) => step.name === 'Cases');

    let newSteps = [...steps];

    newSteps[getIndex] = {
      ...newSteps[getIndex],
      selected: !newSteps[getIndex].selected,
    };

    if(name==='Contact Tracing'){
      newSteps[casesIndex] = {
        ...newSteps[casesIndex],
        queries: [...newSteps[casesIndex].queries, 'contacts'],
      };
    }
    if(name==='Appointments'){
      newSteps[casesIndex] = {
        ...newSteps[casesIndex],
        queries: [...newSteps[casesIndex].queries, 'invitations'],
      };
    }

    setSteps(newSteps);
  };

  useDidMountEffect(() => {
    generateFormList();
  }, [steps]);

  const generateFormList = () => {
    let list = [];
    steps.forEach((step) => {
      if (step.selected) list = [...list, step.name];
    });
    setFormList(list);
  };

  const goBack = () => {
    const currentIndex = formList.indexOf(currentStep);
    if (currentIndex === 0) {
      setCurrentStep("start");
    } else {
      setCurrentStep(formList[currentIndex - 1]);
    }
  };

  const returnComponent = (item) => {
    switch (item) {
      case "Cases":
        return CasesFields;
      case "Contact Tracing":
        return TracingFields;
      case "Appointments":
        return AppointmentFields;
      default:
        return;
    }
  };

  const handleQuery = (name, query) => {
    let getIndex = steps.findIndex((step) => step.name === name);

    let newSteps = [...steps];
    let queries = newSteps[getIndex].queries;

    const exists = queries.includes(query);

    if (exists) {
      const index = queries.indexOf(query);
      queries.splice(index, 1);

      newSteps[getIndex] = {
        ...newSteps[getIndex],
        queries,
      };
    } else {
      newSteps[getIndex] = {
        ...newSteps[getIndex],
        queries: [...queries, query],
      };
      console.log(newSteps[getIndex])
    }
    setSteps(newSteps);
  };

  const setDates = (name, type, date) => {
    let getIndex = steps.findIndex((step) => step.name === name);
    let contactsIndex = steps.findIndex((step) => step.name === 'Contact Tracing');
    let appointmentsIndex = steps.findIndex((step) => step.name === 'Appointments');

    let newSteps = [...steps];

    if (type === "start"){
      newSteps[getIndex] = {
        ...newSteps[getIndex],
        startDate: date,
      };
      if(name==='Cases'){
        newSteps[contactsIndex] = {
          ...newSteps[contactsIndex],
          startDate: date,
        };
        newSteps[appointmentsIndex] = {
          ...newSteps[appointmentsIndex],
          startDate: date,
        }
      }
    }
    if (type === "end"){
      newSteps[getIndex] = {
        ...newSteps[getIndex],
        endDate: date,
      };
      if(name==='Cases'){
        newSteps[contactsIndex] = {
          ...newSteps[contactsIndex],
          endDate: date,
        };
        newSteps[appointmentsIndex] = {
          ...newSteps[appointmentsIndex],
          endDate: date,
        }
      }
    }

    setSteps(newSteps);
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const generatePayload = () => {
    let submitArray = steps.filter((step) => step.queries.length > 0 && step.selected);
    let payload = {};
    submitArray.forEach((item) => {
      if (item.name === "Cases") {
        payload["fields"] = item.queries;
        if (item.endDate) payload["to"] = formatDate(item.endDate)
        if (item.startDate) payload["from"] = formatDate(item.startDate);
      }
      if (item.name === "Contact Tracing") {
        payload["fields_contacts"] = item.queries;
        if (item.endDate) payload["contacts_to"] = formatDate(item.endDate);
        if (item.startDate) payload["contacts_from"] = formatDate(item.startDate);
      }
      if (item.name === "Appointments") {
        payload["fields_invitation"] = item.queries;
        if (item.endDate) payload["invitations_to"] = formatDate(item.endDate);
        if (item.startDate) payload["invitations_from"] = formatDate(item.startDate);
      }
    });
    return payload;
  };

  const errorToast = (msg) => {
    toast({
      position: "top",
      title: "Error!",
      description: msg,
      status: "error",
      duration: 6500,
      isClosable: true,
    });
  }

  const exportData = () => {
    setIsLoading(true);
    const token = `Bearer ${localStorage.getItem("access_token")}`;
    console.log(generatePayload());

    fetch(`${baseUrl}/${version}/cases/export`, {
      method: "POST",
      headers: {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        credentials: "include",
      },
      body: JSON.stringify(generatePayload()),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        setIsLoading(false);
        if (responseJson.status === 201) setSuccess(true);
        if (responseJson.hasOwnProperty("error")) {
          const { error } = responseJson;
          const desc =
            typeof error === "string"
              ? error
              : "Unable to export data, try again.";
          errorToast(desc)
        }
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {!success && (
        <ModalContent maxWidth="760px" border="1px solid rgba(223, 224, 235, 0.7)" borderRadius="8px">
          <Flex
            alignItems="center"
            p="25px 50px"
            borderBottom="1.5px solid rgba(223, 224, 235, 0.5)"
          >
            <Text color="#252733" fontSize={20} fontWeight={500} mr="auto">
              Export Data {formList.includes(currentStep) && `(${currentStep})`}
            </Text>
            {formList.includes(currentStep) && (
              <Flex
                mr="30px"
                onClick={goBack}
                cursor="pointer"
                alignItems="center"
              >
                <BackArrowIcon />
                <Text color="#276DF7" fontSize={14} fontWeight={500} ml="6px">
                  Go back
                </Text>
              </Flex>
            )}
            <Button
              onClick={onClose}
              fontSize={14}
              fontWeight={700}
              color="#161531"
              background="#F3F3F4"
              borderRadius="5px"
              height="35px"
              width="92px"
            >
              cancel
            </Button>
          </Flex>
          {currentStep === "start" && (
            <Start
              steps={steps}
              selectStep={selectStep}
              setCurrentStep={
                formList.length > 0 ? () => setCurrentStep(formList[0]) : null
              }
            />
          )}
          {formList.map((item, index) => {
            const isLast = index === formList.length - 1;
            const FieldsComponentHolder = returnComponent(item);
            const stepObject = steps.find((step) => step.name === item);
            const emptyFields = stepObject.queries.length === 0;
            const errorText = "Select the fields that you want to export."; 
            return (
              <Box key={item}>
                {currentStep === item && (
                  <FieldsComponentHolder
                    buttonText={isLast ? "Export Data" : "Next"}
                    isLoading={isLoading}
                    setCurrentStep={
                      emptyFields
                      ?
                      () => errorToast(errorText)
                      :
                      isLast
                        ? () => exportData()
                        : () => setCurrentStep(formList[index + 1])
                    }
                    handleQuery={(value) => handleQuery(item, value)}
                    queries={stepObject.queries}
                    startDate={stepObject.startDate}
                    endDate={stepObject.endDate}
                    setDates={(type, date) => setDates(item, type, date)}
                  />
                )}
              </Box>
            );
          })}
        </ModalContent>
      )}
      {
        success &&
        <ModalContent width="466px" border="1px solid rgba(223, 224, 235, 0.7)" borderRadius="8px" py="50px">
          <Flex direction="column" alignItems="center">
            <UploadingIcon />
            <Text mt="18px" mb="10px" fontSize={20} fontWeight={500} color="#252733">
            Data Exporting!
            </Text>
            <Text mb="30px" textAlign="center" color="rgba(0, 19, 37, 0.7)" fontSize={15} maxWidth="260px">
              The Exported data will be sent to your email address once completed.
            </Text>
            <ButtonComponent buttonText="Done" triggerAction={onClose}/>
          </Flex>
        </ModalContent>
      }
    </Modal>
  );
};

export default DataExport;
