import React from 'react';
import loginBg from '../../assets/login_bg.png';
import logo from '../../assets/nimr-logo.svg';

const sectionStyle = {
    backgroundImage: `url(${loginBg})`
};

const AuthLayout = ({ children, title }) => {
    return (
        <div className="dashboard-login">
                <div className="left" style={sectionStyle}>
                    {/* <img src={logo} alt="nimr logo"/> */}
                </div>
                <div className="right">
                    <div className="form">
                        <div className="logo-div">
                            <img src={logo} alt="nimr logo"/>
                        </div>
                        <h4>{title}</h4>
                        {children}
                    </div>
                </div>
            </div>
    )
}

export default AuthLayout
