import React from "react";
import { Flex, Grid, Text, ModalFooter, ModalBody } from "@chakra-ui/react";
import { ButtonComponent } from "./Button";
import DateInputs from "./DateInputs";
import { StepCheckIcon, StepUncheckIcon } from "../../Icons";

const fields = [
  {
    name: "First Name",
    value: "first_name",
  },
  {
    name: "Last Name",
    value: "last_name",
  },
  {
    name: "Marital Status",
    value: "marital_status",
  },
  {
    name: "Gender",
    value: "gender",
  },
  {
    name: "Date of Birth",
    value: "dob",
  },
  {
    name: "Phone Number",
    value: "phone_no",
  },
  {
    name: "Email Address",
    value: "email",
  },
  {
    name: "Symptoms",
    value: "symptoms",
  },
  {
    name: "Nationality",
    value: "nationality",
  },
  {
    name: "Pre-existing Condition",
    value: "preExistingConditions",
  },
  {
    name: "Attended Mass Gathering",
    value: "attended_mass_gathering",
  },
  {
    name: "BCG vaccine",
    value: "bcg_vaccine",
  },
  {
    name: "Contact With Suspect",
    value: "contact_with_suspect",
  },
  {
    name: "Case Number",
    value: "case_number",
  },
  {
    name: "Date of Enrollment",
    value: "date_of_enrollment",
  },
  {
    name: "Cases Management Facility",
    value: "employed_by_cases_management_facility",
  },
  {
    name: "Qualified Case",
    value: "qualified_case",
  },
  {
    name: "Sample collected",
    value: "testSubmission",
  },
  {
    name: "Contact With Overseas Traveller",
    value: "contact_with_overseas_traveller",
  },
  {
    name: "Invitations",
    value: "invitations",
  },
  {
    name: "Test Result",
    value: "testResult",
  },
  {
    name: "Countires Visited",
    value: "countries_visited",
  },
  // {
  //   name: "Invitation Dates",
  //   value: "",
  // },
  {
    name: "Consent",
    value: "consent",
  },
  {
    name: "Mode of Transportation",
    value: "mode_of_transportation",
  },
  {
    name: "Contacts",
    value: "contacts",
  },
  {
    name: "Vaccines",
    value: "vaccineType",
  },
];

const CasesFields = ({
  setCurrentStep,
  buttonText,
  handleQuery,
  queries,
  startDate,
  endDate,
  setDates,
  isLoading,
}) => {
  const isSelected = (query) => {
    return Boolean(queries.includes(query));
  };
  return (
    <>
      <ModalBody p="50px">
        <DateInputs
          startDate={startDate}
          endDate={endDate}
          setDates={setDates}
        />
        <Text
          color="rgba(37, 39, 51, 0.85)"
          fontSize={18}
          fontWeight={500}
          mb="35px"
        >
          Select the variable(s) of the data you would like to export
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" rowGap="30px" columnGap="25px">
          {fields.map((field) => {
            return (
              <Flex
                key={field.value}
                justifyContent="space-between"
                alignItems="center"
                borderRadius="4px"
                px="20px"
                background={
                  isSelected(field.value)
                    ? "rgba(39, 109, 247, 0.1)"
                    : "rgba(159, 162, 180, 0.02)"
                }
                border={
                  isSelected(field.value)
                    ? "1px solid #276DF7"
                    : "1px solid rgba(159, 162, 180, 0.4)"
                }
                height="55px"
                onClick={() => handleQuery(field.value)}
                cursor="pointer"
              >
                <Text
                  fontSize={16}
                  fontWeight={500}
                  color={isSelected(field.value) ? "#276DF7" : "#9FA2B4"}
                >
                  {field.name}
                </Text>
                {isSelected(field.value) ? (
                  <StepCheckIcon />
                ) : (
                  <StepUncheckIcon />
                )}
              </Flex>
            );
          })}
        </Grid>
      </ModalBody>

      <ModalFooter pb="50px">
        <ButtonComponent
          buttonText={buttonText}
          triggerAction={setCurrentStep}
          isLoading={isLoading}
        />
      </ModalFooter>
    </>
  );
};

export default CasesFields;
