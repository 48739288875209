import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import Header from '../Header';
import Details from './ProfileDetails';
import PendingCollection from './PendingCollection';
import TestResult from './TestResult';
import PrintOut from './Printout';
import ActionButtons from './ActionButtons';
import Modal from '../modal/Modal';
import SendInvite from '../SendInvite';
import moment from 'moment';
import { apolloQuery, verifyToken } from '../helpers';
import { baseUrl, version } from '../url';
 
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';


const Loader = () => (
    <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "25px",
        height: '100vh'
    }}>
        <i style={{
            fontSize: "36px",
            color: "#6C63FF"
        }}
            className="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
)

toast.configure({
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false
});

export default class CaseProfiles extends Component {
    state = {
        caseProfile: null,
        showModal: false,
        id: null,
        request: false,
        resultModal: false,
        inviteRequest: false,
        inviteModal: false
    }
    componentDidMount() {
        const userRole = localStorage.getItem('user_role');
        const { match: { params } } = this.props;
        const { id } = params;
        this.getCase(id);
        this.setState({
            id,
            userRole
        })
    }
    getCase = async (id) => {
        this.setState({
            response: false,
        })
        await verifyToken();
        const query = `{
          case(id: "${id}"){
              ...on Case{
                data{
                  _id,
                  case_number,
                  first_name,
                  last_name,
                  title,
                  gender,
                  dob,
                  marital_status,
                  symptoms,
                  nationality,
                  countries_visited,
                  home_address,
                  phone_no,
                  email,
                  date_of_enrollment,
                  testSubmission,
                  employed_by_cases_management_facility,
                  attended_mass_gathering,
                  contact_with_overseas_traveller,
                  contact_with_suspect,
                  qualified_case,
                  preExistingConditions,
                  mode_of_transportation,
                  bcg_vaccine,
                  testResult,
                  vaccinated,
                  vaccineType,
                  numberOfDoses,
                  dateOfFirstDose,
                  dateOfSecondDose,
                  invitations{
                    date
                    created_at
                  }
                }
            }
          }
        }`
        const response = await apolloQuery(query);
        const caseProfile = response.case;
        const { data } = caseProfile;
        this.setState({
            caseProfile: data,
            response: true,
        })
    }

    updateCollectionStatus = (id) => {
        this.setState({
            request: true,
        })
        const status = Object.freeze({
            COLLECTED: 'collected',
            PENDING: 'pending',
        });
        const token = `Bearer ${localStorage.getItem('access_token')}`;
        fetch(`${baseUrl}/${version}/cases/test/submission/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'credentials': 'include',
                'Authorization': token
            },
            body: JSON.stringify({
                status: status.COLLECTED
            })
        })
            .then(response => {
                this.setState({
                    request: false
                })
                return response.json()
            })
            .then(responseJson => {
                const { testSubmission } = responseJson;
                if (responseJson.status === 200 || responseJson.status === 201 || testSubmission === "collected") {
                    this.showSuccess("Sample collected from case")
                    this.hideModalOnSuccess();
                }
            })
    }

    sendInvite = async (id, date, time) => {
        this.setState({
            inviteRequest: true,
        })
        const formattedTime = moment(time, 'h:mmA').format('HH:mm:ss');
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const query = `mutation{
            caseInvite(
                id: "${id}",
                date: "${formattedDate} ${formattedTime}"
            ){
              status
            }
          }`
          const data = await apolloQuery(query);
          const caseInvite = data && data.caseInvite;
          const status = caseInvite && caseInvite.status;
    
          this.setState({
            inviteRequest: false
          })
    
          if (status === 200) {
            this.showSuccess('Invite sent');
            this.closeInviteModal();
            this.getCase(id);
          }
    }

    openInviteModal = () => {
        this.setState({
            inviteModal: true
        })
    }

    closeInviteModal = () => {
        this.setState({
            inviteModal: false
        })
    }

    showModal = () => {
        this.setState({
            showModal: true
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false
        })
    }

    showResultModal = () => {
        this.setState({
            resultModal: true
        })
    }

    hideResultModal = () => {
        this.setState({
            resultModal: false
        })
    }

    hideModalOnSuccess = () => {
        const { id } = this.state;
        this.setState({
            showModal: false
        }, () => {
            this.getCase(id);
        })
    }

    showSuccess = (message) => toast.success(message);

    goBack = () => this.props.history.goBack();

    createInitials = (first_name, last_name) => {
        const name = `${first_name} ${last_name}`;
        let matches = name.match(/\b(\w)/g);
        return matches.join('.').toUpperCase();
    }

    render() {
        const { caseProfile, response, showModal, id, request, resultModal, inviteRequest, inviteModal, userRole } = this.state
        const first_name = caseProfile && caseProfile.first_name;
        const last_name = caseProfile && caseProfile.last_name;
        const case_number = caseProfile && caseProfile.case_number;
        const testSubmission = caseProfile && caseProfile.testSubmission;
        const testResult = caseProfile && caseProfile.testResult;
        const invitations = caseProfile && caseProfile.invitations;
        return (
            <React.Fragment>
                <Header />
                {
                    caseProfile &&
                    <div className="enrollee-profile">
                        <div className="back" onClick={this.goBack}>
                            <i className="fa fa-arrow-left"></i>
                            Back
                        </div>
                        <div className="enrollee-top">
                            <div className="name-div">
                                <h4>{first_name} {last_name}</h4>
                                <div className="status">
                                    {testResult ? testResult : 'No test yet'}
                                </div>
                            </div>
                            <ActionButtons 
                                userRole={userRole}
                                invitations={invitations}
                                testSubmission={testSubmission}
                                testResult={testResult}
                                inviteRequest={inviteRequest}
                                openInviteModal={this.openInviteModal}
                                showCollectionModal={this.showModal}
                                showResultModal={this.showResultModal}
                            />
                            <div className="progress-tabs">
                                <div className="tab done">
                                    <i className="fa fa-check-circle"/>
                                    Request Submitted
                                </div>
                                <div className={`tab ${!invitations ? '' : 'done'}`}>
                                    <i className="fa fa-check-circle"/>
                                    Invite sent
                                </div>
                                <div className={`tab ${!testSubmission || testSubmission === 'PENDING' ? '' : 'done'}`}>
                                    <i className="fa fa-check-circle"/>
                                    Sample collected
                                </div>
                                <div className={`tab ${!testResult ? '' : 'done'}`}>
                                    <i className="fa fa-check-circle"/>
                                    Test completed
                                </div>
                            </div>
                        </div>
                        <div className="main">
                            <div className="left">
                                <div className="top">
                                    <h5>Enrollee Information</h5>
                                    <ReactToPrint
                                        trigger={() => <button>Print case number</button>}
                                        content={() => this.componentRef}
                                        pageStyle="@page { size: 1.2in 0.9in}"
                                    />
                                    <div style={{ display: 'none' }}>
                                        <PrintOut
                                            ref={el => (this.componentRef = el)}
                                            case_number={case_number}
                                            initials={this.createInitials(first_name, last_name)}
                                        />
                                    </div>
                                </div>
                                <Details details={caseProfile} />
                            </div>
                        </div>
                    </div>
                }
                {
                    !response && !caseProfile && <Loader />
                }
                <Modal show={showModal}>
                    <PendingCollection
                        hide={this.hideModal}
                        updateCollectionStatus={this.updateCollectionStatus}
                        request={request}
                        id={id}
                    />
                </Modal>
                <Modal show={resultModal}>
                    <TestResult
                        hide={this.hideResultModal}
                        id={id}
                        getCase={this.getCase} />
                </Modal>
                <Modal show={inviteModal}>
                    {inviteModal && <SendInvite
                        close={this.closeInviteModal}
                        sendInvite={this.sendInvite}
                        id={id}
                        inviteRequest={inviteRequest}
                    />}
                </Modal>

            </React.Fragment>
        )
    }
}
