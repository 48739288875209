import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logout } from "./helpers";

import logo from "../assets/nimr-logo.svg";
import logout_icon from "../assets/logout-icon.svg";
import profile_icon from "../assets/profile-icon.svg";
import { baseUrl, version } from "./url";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Flex,
  Text,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

class Header extends Component {
  state = {
    account: null,
  };

  componentDidMount() {
    this.fetchAccount();
  }

  fetchAccount = () => {
    let accountDetails = JSON.parse(localStorage.getItem("account"));

    if (!accountDetails) {
      const token = `Bearer ${localStorage.getItem("access_token")}`;

      fetch(`${baseUrl}/${version}/admin/auth/profile`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          const { email, fullName } = responseJson;
          accountDetails = { email, fullName };
          localStorage.setItem("account", JSON.stringify(accountDetails));
          this.setState({
            account: accountDetails,
          });
        });
    }

    this.setState({
      account: accountDetails,
    });
  };

  overview = () => {
    this.props.history.push({
      pathname: `/overview`,
    });
  };
  caseList = () => {
    this.props.history.push({
      pathname: `/case-list/1/20`,
    });
  };
  appointments = () => {
    this.props.history.push({
      pathname: `/appointments`,
    });
  };
  profile = () => {
    this.props.history.push({
      pathname: "/account/details",
    });
  };
  render() {
    const { page } = this.props;
    const { account } = this.state;
    const fullName = account?.fullName;
    return (
      <div className="header">
        <img src={logo} alt="" />
        <div className="nav-links">
          <div
            className={`nav-link${page === "dashboard" ? " active" : ""}`}
            onClick={this.caseList}
          >
            <span>Cases</span>
            <div className="bar"></div>
          </div>
          <div
            className={`nav-link${page === "appointments" ? " active" : ""}`}
            onClick={this.appointments}
          >
            <span>Appointments</span>
            <div className="bar"></div>
          </div>
          <div
            className={`nav-link${page === "overview" ? " active" : ""}`}
            onClick={this.overview}
          >
            <span>Overview</span>
            <div className="bar"></div>
          </div>
        </div>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            borderRadius="50px"
            background="rgba(39, 109, 247, 0.08)"
            px="10px"
            color="#333333"
          >
            <Flex alignItems="center" color="#333333">
              <Avatar size="xs" name={fullName} marginRight="10px" />
              <Text color="#333333" fontSize={14} fontWeight={500}>{fullName}</Text>
            </Flex>
          </MenuButton>
          <MenuList borderRadius="lg" background="#EEF3FE">
            <MenuItem onClick={this.profile}>
              <img
                src={profile_icon}
                alt=""
                style={{ height: "13px", width: "auto", marginRight: "15px" }}
              />
              Profile
            </MenuItem>
            <MenuItem onClick={logout}>
              <img
                src={logout_icon}
                alt=""
                style={{ height: "13px", width: "auto", marginRight: "15px" }}
              />
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    );
  }
}

export default withRouter(Header);
