import React from "react";
// import DatePicker from "react-datepicker";
import { Box, Flex, Text } from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // BarChart,
  // Bar,
  // LabelList,
} from "recharts";

// import calendar_icon from "../../assets/calendar-icon.svg";

import "./style.scss";

const SubmissionsGraph = ({
  dates,
  selectDates,
  setShoWDatePicker,
  showDatePicker,
  data,
}) => {
  return (
    <Box
      bg="#FFFFFF"
      border="1px solid rgba(223, 224, 235, 0.7)"
      borderRadius="8px"
      className="submission-graph"
    >
      <Flex
        px="30px"
        py="22px"
        borderBottom="1.5px solid rgba(223, 224, 235, 0.5)"
        // justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Text color="rgba(37, 39, 51, 0.85)" fontSize={16}>
          Number Of Submission Overtime
        </Text>
        {/* <Button
          rightIcon={<img src={calendar_icon} alt="" />}
          bg="tranparent"
          border="1px solid #276DF7"
          color="#276DF7"
          _hover=""
          onClick={() => setShoWDatePicker(!showDatePicker)}
          ml="auto"
        >
          Select Date
        </Button>
        <DatePicker
          className="date-picker"
          selected={dates.state}
          onChange={selectDates}
          startDate={dates.start}
          endDate={dates.end}
          open={showDatePicker}
          showPopperArrow={false}
          monthsShown={2}
          selectsRange
          popperPlacement="top-end"
        /> */}
      </Flex>
      <Box p="0 40px 20px 20px">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            // width={100}

            data={data}
            margin={{
              top: 40,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              strokeDasharray="1 1"
              stroke="#ddd"
              vertical={false}
            />
            <XAxis dataKey="date" tick={{fontSize: 12}} />
            <YAxis strokeWidth={0} tick={{fontSize: 12}} />
            <Tooltip />
            {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
            <Line
              type="monotone"
              dataKey="cases"
              stroke="#276DF7"
              strokeWidth={1}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
        <Text textAlign="center" color="#0E62FF" fontSize={11} fontWeight={500}>Date</Text> 
      </Box>
    </Box>
  );
};

export default SubmissionsGraph;
