import { createApolloFetch } from 'apollo-fetch';
import { baseUrl } from './url';
import moment from 'moment';

const uri = `${baseUrl}/graphql`;
const apolloFetch = createApolloFetch({ uri });

const isObject = val => {
  return typeof val === 'object' && val !== null;
};

export const classnames = (...args) => {
  const classes = [];
  args.forEach(arg => {
    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (isObject(arg)) {
      Object.keys(arg).forEach(key => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    } else {
      throw new Error(
        '`classnames` only accepts string or object as arguments'
      );
    }
  });

  return classes.join(' ');
};

export const verifyToken = async () => {
  const tokenTime = localStorage.getItem('token_time');
  const now = moment();
  const hoursPassed = moment.duration(now.diff(tokenTime)).get('hours');
  const daysPassed = moment.duration(now.diff(tokenTime)).get('days');

  if (daysPassed >= 14) {
    logout();

    return null;
  }

  if (hoursPassed >= 2) {
    await refreshToken();
  }

  let token = localStorage.getItem('access_token');

  return token;
}

export const refreshToken = async () => {
  const query = `{
    tokenRefresh{
      ...on Login {
        data {
          token
          _meta {
            validFor
            expiresIn
          }
        }
      }
    }
  }`

  const token = await apolloQuery(query);
  const { tokenRefresh } = token;
  const { data } = tokenRefresh;
  if (data && data.hasOwnProperty('token')){
    const { token } = data;
    const date = moment();
    localStorage.setItem('access_token', token);
    localStorage.setItem('token_time', date);
  }else{
    logout();
  }
}

export const apolloQuery = async (query) => {

  const token = `Bearer ${localStorage.getItem('access_token')}`;

  apolloFetch.use(({ request, options }, next) => {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['authorization'] = token;

    next();
  });

  apolloFetch.useAfter(({ response }, next) => {
    if (response.status === 401) {
      logout();
    }
    next();
  });

  const response = await apolloFetch({ query })
  const { data } = response;

  return data;
}

export const logout = () => {
  localStorage.clear();
  window.location.reload();
}

export const calculateTotalPages = (totalEntries, itemsPerPage) => {
  const entriesLength = totalEntries;
  const totalPages = entriesLength/itemsPerPage;
  return {
    entriesLength,
    totalPages: Math.ceil(totalPages),
  }
}