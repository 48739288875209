import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AccountLayout from "./AccountLayout";

import { Box, Text, Stack, Flex, Button, useToast } from "@chakra-ui/react";

import { TextInput } from "./Input";
import { baseUrl, version } from "../url";

/**
 * password_one: fd9e9bda684a7a00c3d
 * password_two: fd9e9bda684a7a00c3a
 */

const PassWordSettings = () => {
  const toast = useToast();
  const history = useHistory();

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false);

  const changePassword = () => {
    const token = `Bearer ${localStorage.getItem("access_token")}`;

    const bodyObj = {
      oldPassword,
      newPassword
    };

    setIsLoading(true);

    fetch(`${baseUrl}/${version}/admin/auth/change/password`, {
      method: "PUT",
      headers: {
        'Authorization': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'credentials': 'include'
      },
      body: JSON.stringify(bodyObj)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setIsLoading(false)
        if (responseJson.status === 200){
          toast({
            position: "top",
            title: "Password Updated",
            description: "Your password has been updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          })
          history.push('/account/details');
        }
        if (responseJson.hasOwnProperty('error')) {
          const { error } = responseJson;
          const desc = typeof error === 'string' ? error : "Unable to update password."
          toast({
            position: "top",
            title: "An error occurred.",
            description: desc,
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        }
      })
      .catch(e => {
        console.log(e.response);
      });
  }

  return (
    <AccountLayout>
      <Box
        background="#FFFFFF"
        borderRadius="10px"
        py="50px"
        px="110px"
        p="50px 110px 100px"
      >
        <Text color="#252733" fontSize={24} fontWeight={500} mb="50px">
          Password Settings
        </Text>
        <Stack spacing="30px">
          <TextInput label="Old Password" placeholder="Old Password" handleInput={(value) => setOldPassword(value)} value={oldPassword} type="password" />
          <TextInput label="New Password" placeholder="New Password" handleInput={(value) => setNewPassword(value)} value={newPassword} type="password" />
          <Flex pt="30px" justifyContent="space-between">
            <Button h="55px" w="162px" background="#F3F3F4">
              <Text color="#161531" fontWeight="bold" fontSize="15px">Cancel</Text>
            </Button>
            <Button
              isLoading={isLoading}
              background="#276DF7"
              h="55px"
              w="162px"
              _hover=""
              onClick={changePassword}
              disabled={!(oldPassword && newPassword)}
            >
              <Text color="#ffffff" fontWeight="bold" fontSize="15px">Change Password</Text>
            </Button>
          </Flex>
        </Stack>
      </Box>
    </AccountLayout>
  );
};

export default PassWordSettings;
