import React, { Component } from "react";
import { baseUrl, version } from "../url";
import { Redirect, Link } from "react-router-dom";
import moment from "moment";
import AuthLayout from "./AuthLayout";

class Login extends Component {
  state = {
    username: "",
    password: "",
    usernameError: "",
    passwordError: "",
    error: "",
    login: false,
    response: true,
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
      error: "",
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    if (!username) {
      this.setState({
        usernameError: "Field cannot be empty",
      });
    }
    if (!password) {
      this.setState({
        passwordError: "Field cannot be empty",
      });
    }
    if (username && password) {
      this.setState({
        response: false,
      });
      fetch(`${baseUrl}/${version}/admin/auth/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      })
        .then((response) => {
          this.setState({
            response: true,
          });
          return response.json();
        })
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.hasOwnProperty("error")) {
            if (responseJson.error) {
              this.setState({
                error: responseJson.error,
              });
            }
          } else if (responseJson.hasOwnProperty("token")) {
            const date = moment();
            const { token, roles } = responseJson;
            localStorage.setItem("access_token", token);
            localStorage.setItem("token_time", date);
            localStorage.setItem("user_role", roles[0]);
            this.setState({ login: true });
          }
        });
    }
  };
  render() {
    const {
      username,
      password,
      usernameError,
      passwordError,
      error,
      login,
      response,
    } = this.state;
    const { from } = this.props.location.state || {
      from: { pathname: "/case-list" },
    };
    const token = localStorage.getItem("access_token");
    if (token || login) {
      return <Redirect to={from} />;
    }
    return (
      <AuthLayout title="Login to Enrollment Dashboard">
        <form>
          <div className={`input-div ${usernameError ? "error" : ""}`}>
            <label>Email Address</label>
            <input
              type="email"
              name="username"
              onChange={this.handleChange}
              value={username}
            />
            <span>{usernameError}</span>
          </div>
          <div className={`input-div ${passwordError ? "error" : ""}`}>
            <label>Password</label>
            <input
              type="password"
              name="password"
              onChange={this.handleChange}
              value={password}
            />
            <span>{passwordError}</span>
          </div>
          <Link to="/forgot-password" className="forgot-password-link">
            Forgot password?
          </Link>
          <button onClick={this.handleSubmit}>
            Login
            {!response && <i className="fa fa-spinner fa-pulse fa-fw"></i>}
          </button>
          <p>{error}</p>
        </form>
      </AuthLayout>
    );
  }
}

export default Login;
