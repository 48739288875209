import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';
import moment from 'moment';

const monthMap = {
  '1': 'January',
  '2': 'February',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

const dateConfig = {
  'date': {
      format: 'DD',
      caption: 'Day',
      step: 1,
  },
  'month': {
      format: value => monthMap[value.getMonth() + 1],
      caption: 'Month',
      step: 1,
  },
  'year': {
      format: 'YYYY',
      caption: 'Year',
      step: 1,
  },
};

export default class PersonalSection extends Component {
  state = {
    isOpen: false
  }

  openDatePicker = () => this.setState({ isOpen: true })

  closeDatePicker = () => this.setState({ isOpen: false })

  selectDate = (date) => {
    this.setState({ date, isOpen: false}, () => {
      this.props.handleDOB(moment(date).format('YYYY-MM-D'))
    })
  }

  render() {
    const {
      title,
      sex,
      first_name,
      last_name,
      dob,
      marital_status,
      handleChange,
      handleOptions,
      incompleteFields,
      next,
    } = this.props;
    const birthDate = moment(dob, 'YYYY-MM-DD');
    const now = moment();
    const age = moment.duration(now.diff(birthDate)).get('years');
    return (
      <div className="form-sections">
        <h4 className="section-title mobile">Personal Details</h4>
        <div className="section-content">
          <h3 className="form-title desktop">Covid-19 Testing Program <br /> (Request Form)</h3>
          <p className="description desktop">
            NIMR in partnership with <span>LifeBank</span> is conducting Drive-through
              Test to make it easier for people to get tested and prevent the
              spread of CoVid-19. Kindly register below to get tested at no cost.
          </p>
          <h4 className="section-title desktop">Personal Details</h4>
          <label className={incompleteFields && !title ? 'error' : ''}>
            <span className="star">*</span>Title
            <div className="input-options four">
              <div className={`option ${title === "mr" ? 'selected' : ''}`}
                onClick={() => handleOptions("title", "mr")}>
                Mr
              </div>
              <div className={`option ${title === "mrs" ? 'selected' : ''}`}
                onClick={() => handleOptions("title", "mrs")}>
                Mrs
              </div>
              <div className={`option ${title === "dr" ? 'selected' : ''}`}
                onClick={() => handleOptions("title", "dr")}>
                Dr
              </div>
              <div className={`option ${title === "miss" ? 'selected' : ''}`}
                onClick={() => handleOptions("title", "miss")}>
                Miss
              </div>
            </div>
          </label>
          <label className={incompleteFields && !sex ? 'error' : ''}>
            <span className="star">*</span>Sex
              <div className="input-options two">
              <div className={`option ${sex === "male" ? 'selected' : ''}`}
                onClick={() => handleOptions("sex", "male")}>
                Male
              </div>
              <div className={`option ${sex === "female" ? 'selected' : ''}`}
                onClick={() => handleOptions("sex", "female")}>
                Female
              </div>
            </div>
          </label>
          <label className={incompleteFields && !first_name ? 'error' : ''}>
            <span className="star">*</span>First Name
            <input
              type="text"
              name="first_name"
              placeholder="Enter First Name"
              value={first_name}
              onChange={handleChange} />
          </label>
          <label className={incompleteFields && !last_name ? 'error' : ''}>
            <span className="star">*</span>Last Name
            <input
              type="text"
              name="last_name"
              placeholder="Enter Last Name"
              value={last_name}
              onChange={handleChange} />
          </label>
          <label className={`dob-label desktop ${incompleteFields && !dob ? 'error' : ''}`}>
            <span className="star">*</span>Date of Birth
            <input
              type="date"
              name="dob"
              placeholder="dd-mm-yy"
              value={dob}
              onChange={handleChange}
              className={`date-input ${age <= 2 ? 'warning-input' : ''}`}/>
            {
              age <= 2 && 
              <p className="warning">
                Please enter the correct date of birth. This information will be required at the test center.
              </p>
            }
          </label>
          <label className={`dob-label mobile ${incompleteFields && !dob ? 'error' : ''}`}>
            <span className="star">*</span>Date of Birth
            <div className={`input date-input ${age <= 2 ? 'warning-input' : ''}`}
              onClick={this.openDatePicker}
            >
              {dob? moment(dob, 'YYYY-MM-D').format('DD / MM / YYYY') : <p>dd/mm/yyyy</p>}
              <i className="fa fa-calendar" />
            </div>
            {
              age <= 2 && 
              <p className="warning">
                Please enter the correct date of birth. This information will be required at the test center.
              </p>
            }
          </label>
          <label className={incompleteFields && !marital_status ? 'error' : ''}>
            <span className="star">*</span>Marital Status
            <select name="marital_status" onChange={handleChange} value={marital_status}>
              <option value="">Select your marital status</option>
              <option value="single">Single</option>
              <option value="married">Married</option>
              <option value="divorced">Divorced</option>
              <option value="separated">Separated</option>
            </select>
          </label>
          <div className="button-section">
            <button className="right-button" onClick={next}>Next</button>
          </div>

          <DatePicker
            dateConfig={dateConfig}
            showCaption={true}
            value={this.state.date}
            isOpen={this.state.isOpen}
            confirmText="Confirm"
            cancelText="Cancel"
            headerFormat="DD/MM/YYYY"
            min={new Date(1900, 0, 1)}
            onSelect={this.selectDate}
            onCancel={this.closeDatePicker}/>
            
        </div>
      </div>
    )
  }
}
