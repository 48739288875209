import React, { Component } from 'react'

export default class SymptomsSection extends Component {
  render() {
    const {
      travelled,
      countries_visited,
      handleChange,
      handleOptions,
      handleSymptoms,
      handleSymptomsStyle,
      noSymptom,
      otherSymptoms,
      next,
      back,
    } = this.props;

    const symptomOptions = [
      'Loss of taste and smell',
      'Shortness of breath',
      'Chest pain',
      'Loss of speech and movement',
      'Fever or chills',
      'Dry cough',
      'Fatigue',
      'Muscle and body aches',
      'Sore throat',
      'Diarrhoea',
      'Headaches',
      'Runny nose',
      'Nausea or vomiting'
    ]
    
    return (
      <div className="form-sections">
        <h4 className="section-title mobile">Log Symptoms</h4>
        <div className="section-content">
        <h4 className="section-title desktop">Log Symptoms</h4>
          <label>
            Have you experienced any of these symptoms in the last 1 week?
            <div className="options-list">
              <div
                className={handleSymptomsStyle('No Symptoms') ? "option selected" : "option"}
                onClick={() => handleSymptoms('No Symptoms')}
                >
                <p>No Symptoms</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div>
              {
                symptomOptions.map(option => (
                  <div
                    key={option}
                    className={`option ${handleSymptomsStyle(option) ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                    onClick={() => {!noSymptom && handleSymptoms(option)}}
                    >
                    <p>{option}</p>
                    <div className="check">
                      <i className="fa fa-check-circle" />
                    </div>
                  </div>
                ))
              }
              {/* <div
                className={`option ${handleSymptomsStyle('Shortness of Breath') ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                onClick={() => {!noSymptom && handleSymptoms('Shortness of Breath')}}
                >
                <p>Shortness of Breath</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div>
              <div
                className={`option ${handleSymptomsStyle('Shortness of Breath') ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                onClick={() => {!noSymptom && handleSymptoms('Shortness of Breath')}}
                >
                <p>Shortness of Breath</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div>
              <div
                className={`option ${handleSymptomsStyle('Sore throat') ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                onClick={() => {!noSymptom && handleSymptoms('Sore throat')}}
                >
                <p>Sore throat</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div>
              <div
                className={`option ${handleSymptomsStyle('Fever') ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                onClick={() => {!noSymptom && handleSymptoms('Fever')}}
                >
                <p>Fever</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div>
              <div
                className={`option ${handleSymptomsStyle('Nasal Congestion') ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                onClick={() => {!noSymptom && handleSymptoms('Nasal Congestion')}}
                >
                <p>Nasal Congestion</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div>
              <div
                className={`option ${handleSymptomsStyle('Dry cough') ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                onClick={() => {!noSymptom && handleSymptoms('Dry cough')}}
                >
                <p>Dry cough</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div>
              <div
                className={`option ${handleSymptomsStyle('Running Nose') ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                onClick={() => {!noSymptom && handleSymptoms('Running Nose')}}
                >
                <p>Running Nose</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div>
              <div
                className={`option ${handleSymptomsStyle('Diarrhea') ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                onClick={() => {!noSymptom && handleSymptoms('Diarrhea')}}
                >
                <p>Diarrhea</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div>
              <div
                className={`option ${handleSymptomsStyle('Andominal pain') ? "selected" : ""} ${noSymptom ? "disabled" : ""}`}
                onClick={() => {!noSymptom && handleSymptoms('Andominal pain')}}
                >
                <p>Abdominal pain</p>
                <div className="check">
                  <i className="fa fa-check-circle" />
                </div>
              </div> */}
            </div>
          </label>
          <label>
            <textarea
              name="other_symptoms"
              onChange={handleChange}
              placeholder="Others (separate by comma)"
              disabled={noSymptom}
              value={otherSymptoms}
            />
          </label>
          <label>
            Have you been to any country(ies) outside your country of residence in the past month?
            <div className="input-options two">
              <div className={`option ${travelled === "yes" ? 'selected' : ''}`}
                onClick={() => handleOptions('travelled', 'yes')}>
                Yes
              </div>
              <div className={`option ${travelled === "no" ? 'selected' : ''}`}
                onClick={() => handleOptions('travelled', 'no')}>
                No
              </div>
            </div>
          </label>
          {
            travelled === "yes" &&
            <label>
              Countries Travelled (within the past month)
              <input
                type="text"
                name="countries_visited"
                placeholder="Country 1, Country 2"
                value={countries_visited}
                onChange={handleChange} />
            </label>
          }
          <div className="button-section">
            <button onClick={back}>Back</button>
            <button className="right-button" onClick={next}>Next</button>
          </div>
        </div>
      </div>
    )
  }
}
