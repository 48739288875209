import React from "react";
import { Flex, Grid, Text, ModalFooter, ModalBody } from "@chakra-ui/react";
import { ButtonComponent } from "./Button";
import DateInputs from "./DateInputs";
import { StepCheckIcon, StepUncheckIcon } from "../../Icons";

const fields = [
  {
    name: "Appointment Date",
    value: "appointment_date",
  },
  {
    name: "Appointment Time",
    value: "appointment_time",
  },
  {
    name: "Case Number",
    value: "case_number",
  },
  {
    name: "Name",
    value: "name",
  },
  {
    name: "Email Address",
    value: "email",
  },
  {
    name: "Phone Number",
    value: "phone",
  },
  {
    name: "Date Sent",
    value: "date_sent",
  },
  {
    name: "Admin Who Sent Invite",
    value: "admin",
  },
];

const AppointmentFields = ({
  setCurrentStep,
  buttonText,
  handleQuery,
  queries,
  startDate,
  endDate,
  setDates,
  isLoading
}) => {
  const isSelected = (query) => {
    return Boolean(queries.includes(query));
  };
  return (
    <>
      <ModalBody p="50px">
        <DateInputs
          startDate={startDate}
          endDate={endDate}
          setDates={setDates}
        />
        <Text
          color="rgba(37, 39, 51, 0.85)"
          fontSize={18}
          fontWeight={500}
          mb="35px"
        >
          Select the variable(s) of the data you would like to export
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" rowGap="30px" columnGap="25px">
          {fields.map((field) => {
            return (
              <Flex
                key={field.value}
                justifyContent="space-between"
                alignItems="center"
                borderRadius="4px"
                px="20px"
                background={
                  isSelected(field.value)
                    ? "rgba(39, 109, 247, 0.1)"
                    : "rgba(159, 162, 180, 0.02)"
                }
                border={
                  isSelected(field.value)
                    ? "1px solid #276DF7"
                    : "1px solid rgba(159, 162, 180, 0.4)"
                }
                height="55px"
                onClick={() => handleQuery(field.value)}
                cursor="pointer"
              >
                {/* <step.icon fill={step.selected ? "#276DF7" : "#9FA2B4"} /> */}
                <Text
                  fontSize={16}
                  fontWeight={500}
                  color={isSelected(field.value) ? "#276DF7" : "#9FA2B4"}
                >
                  {field.name}
                </Text>
                {isSelected(field.value) ? (
                  <StepCheckIcon />
                ) : (
                  <StepUncheckIcon />
                )}
              </Flex>
            );
          })}
        </Grid>
      </ModalBody>

      <ModalFooter pb="50px">
        <ButtonComponent
          buttonText={buttonText}
          triggerAction={setCurrentStep}
          isLoading={isLoading}
        />
      </ModalFooter>
    </>
  );
};

export default AppointmentFields;
