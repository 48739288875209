import React, { Component } from 'react'

export default class ActionButtons extends Component {
    render() {
        const {
            userRole,
            invitations,
            openInviteModal,
            testSubmission,
            showCollectionModal,
            showResultModal,
            inviteRequest,
            testResult
        } = this.props;
        
        let inviteButton;

        if(!invitations){
            inviteButton = (
                <button className="send-invite" onClick={() => openInviteModal()}>
                    <i className={inviteRequest ? "fa fa-spinner fa-pulse fa-fw" : "fa fa-paper-plane"} />
                    Send invite
                </button>
            )
        }

        if(invitations && invitations.length < 5){
            inviteButton = (
                <button className="send-invite" onClick={() => openInviteModal()}>
                    <i className={inviteRequest ? "fa fa-spinner fa-pulse fa-fw" : "fa fa-paper-plane"} />
                    Resend invite ({5 - invitations.length})
                </button>
            )
        }

        if(invitations && invitations.length === 5){
            inviteButton = (
                <div className="invite-sent">
                    <i className="fa fa-check" />
                    invite sent
                </div>
            )
        }

        return (
            <React.Fragment>
                {
                    userRole === "super_admin" &&
                    <div className="action-buttons">
                        {inviteButton}
                        <div className="divider"></div>
                        {
                            !testSubmission || testSubmission === 'PENDING' ?
                                <button onClick={showCollectionModal} className="sample-button"> <i className="fa fa-plus" /> Collect sample</button>
                                :
                                <div className="collected">
                                    <i className="fa fa-check" />
                                    Sample Collected
                                </div>
                        }
                        <div className="divider"></div>
                        <button className="test-btn" onClick={showResultModal}>{!testResult ? 'Add test result' : 'Update test result'}</button>
                    </div>
                }
                {
                    userRole==="centre_admin" &&
                    <div className="action-buttons">
                        {inviteButton}
                    </div>
                }
                {
                    userRole==="lab_manager" &&
                    <div className= "action-buttons">
                        {
                            !testSubmission || testSubmission === 'PENDING' ?
                                <button onClick={showCollectionModal} className="sample-button"> <i className="fa fa-plus" /> Collect sample</button>
                                :
                                <div className="collected">
                                    <i className="fa fa-check" />
                                    Sample Collected
                                </div>
                        }
                        <div className="divider"></div>
                        <button className="test-btn" onClick={showResultModal}>{!testResult ? 'Add test result' : 'Update test result'}</button>
                    </div>
                }
            </React.Fragment>
        )
    }
}
