import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { classnames } from '../helpers';

import gLogo from '../../assets/google_logo.png';

export default function GoogleAddress(props) {
  return (
    <div>
      <PlacesAutocomplete
        onChange={props.handleAddress}
        value={props.address}
        onSelect={props.handleSelect}
        onError={props.handleError}
        shouldFetchSuggestions={props.address.length > 4}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <React.Fragment>
              <label className={props.incompleteFields && !props.address? 'error' : ''}>
                <span className="star">*</span>Home Address
                  <textarea
                  {...getInputProps({
                    placeholder: 'Enter Address',
                    row: '6',
                    name: 'address'
                  })}
                />
                {
                suggestions.length > 0 && (
                  <div className="autocomplete">
                    {suggestions.map(suggestion => {
                      const className = classnames('autocomplete-item', {
                        'autocomplete-item--active': suggestion.active,
                      });
                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <i className="fa fa-map-marker" />
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                    <div className="bottom">
                      <div>
                        <img
                          src={gLogo}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                )}
              </label>
              
            </React.Fragment>
          );
        }}
      </PlacesAutocomplete>
    </div>
  )
}
