import React, { Component } from "react";
import Header from "../Header";
import {
  apolloQuery,
  // verifyToken,
  // calculateTotalPages
} from "../helpers";
import { CSVLink } from "react-csv";
import moment from "moment";
import DatePicker from "react-datepicker";

import Loader from "../Loader";

import search_icon from "../../assets/search-icon.svg";
import calendar_icon from "../../assets/calendar-icon.svg";

import "react-datepicker/dist/react-datepicker.css";

export default class Appointments extends Component {
  state = {
    skip: 0,
    perPage: 0,
    searchQuery: "",
    datePickerIsOpen: false,
    startDate: new Date(),
    endDate: new Date(),
  };

  componentDidMount() {
    this.getInvites();
  }

  getInvites = async () => {
    this.setState({
      isLoading: true,
      data: [],
    });
    const { skip, perPage, startDate, endDate, searchQuery } = this.state;
    let qs = [];
    // if (date) qs[qs.length] = `date:"${date}"`;
    if (startDate && endDate)
      qs[qs.length] = `from: "${moment(startDate).format(
        "YYYY-MM-DD"
      )}", to: "${moment(endDate).format("YYYY-MM-DD")}"`;
    if (perPage) qs[qs.length] = `limit: ${perPage}`;
    if (skip) qs[qs.length] = `skip: ${skip}`;
    if (searchQuery) qs[qs.length] = `query: "${searchQuery}"`;

    const query = `{
            ${qs.length > 0 ? `invites(${qs.join(",")})` : "invites"}{
                ...on ErrorResponse{
                  status
                  error
                }
                ...on Invites{
                  data
                  meta{
                    ...on Pagination{
                      total
                    }
                  }
                }
            }
        }`;
    const response = await apolloQuery(query);
    const { invites } = response;
    const { analysis, data, meta } = invites;
    this.setState({
      analysis,
      data,
      meta,
      isLoading: false,
    });
  };

  setDate = (dates) => {
    const [startDate, endDate] = dates;
    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        if (startDate && endDate) {
          this.openDatePicker();
          this.getInvites();
        }
      }
    );
  };

  openDatePicker = () => {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  };

  handleSearchQuery = (e) => {
    this.setState(
      {
        searchQuery: e.target.value,
      },
      () => this.getInvites()
    );
  };

  render() {
    const { data, datePickerIsOpen, isLoading, startDate, endDate } =
      this.state;
    const startDateString = moment(startDate).format("MMM D, YYYY");
    const endDateString = moment(endDate).format("MMM D, YYYY");
    const today = moment().format("MMM D, YYYY");
    let buttonLabel;
    if (startDateString === today && endDateString === today) {
      buttonLabel = "Today";
    } else if (startDateString === endDateString) {
      buttonLabel = startDateString;
    } else {
      buttonLabel = `${startDateString} - ${
        endDateString === "Invalid date" ? "" : endDateString
      }`;
    }

    const headers = [
      { label: "Date", key: "date" },
      { label: "Time", key: "time" },
      { label: "Clinic No", key: "case_number" },
      { label: "Name", key: "name" },
      { label: "Email Address", key: "email" },
      { label: "Phone Number", key: "phone_no" },
    ];

    return (
      <React.Fragment>
        <Header page="appointments" />
        <div className="appointments">
          <h3 className="appointments-title">All Appointments</h3>
          <div className="search-download">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search for first names, last namaes, clinic no"
                onChange={this.handleSearchQuery}
              />
              <img src={search_icon} alt="" />
            </div>
            <CSVLink
              data={data ? data : []}
              headers={headers}
              filename={`invitees-${Date.now()}.csv`}
              onClick={this.exportCSV}
              className="csv-button"
            >
              Download List
            </CSVLink>
          </div>
          <div className="invitees-list">
            <div className="top">
              <h4>Invitees</h4>
              <button className="date-btn" onClick={this.openDatePicker}>
                {buttonLabel}
                <img src={calendar_icon} alt="" />
              </button>
              <DatePicker
                className="date-picker"
                selected={startDate}
                onChange={this.setDate}
                startDate={startDate}
                endDate={endDate}
                open={datePickerIsOpen}
                popperPlacement="bottom-start"
                selectsRange
              />
            </div>
            <table>
              <tbody>
                <tr>
                  <th>Date</th>
                  <th>Clinic No</th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                </tr>
                {data &&
                  data.map((entry, index) => {
                    const {
                      case_number,
                      first_name,
                      last_name,
                      date,
                      email,
                      phone_no,
                      time,
                    } = entry;
                    return (
                      <tr key={index}>
                        <td>
                          <div className="date">
                            <h4>{date}</h4>
                            <h5>{time}</h5>
                          </div>
                        </td>
                        <td>{case_number}</td>
                        <td>{`${first_name} ${last_name}`}</td>
                        <td>{email}</td>
                        <td>{phone_no}</td>
                      </tr>
                    );
                  })}
                {data && data.length === 0 && !isLoading && (
                  <tr>
                    <td colSpan="5">
                      <h4 style={{ textAlign: "center" }}>
                        There are no invitees to display for {buttonLabel}
                      </h4>
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan="5">
                      <Loader />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
