import React from "react";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

const AgeGroupChart = ({ data }) => {
  const toPercent = (value) => {
    return `${value}%`;
  };
  return (
    <Box
      bg="#FFFFFF"
      border="1px solid rgba(223, 224, 235, 0.7)"
      borderRadius="8px"
    >
      <Flex
        px="30px"
        py="22px"
        borderBottom="1.5px solid rgba(223, 224, 235, 0.5)"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Text color="rgba(37, 39, 51, 0.85)" fontSize={16}>
          Application Age Group Distribution
        </Text>
        <HStack spacing="30px">
          <Flex alignItems="center">
            <Box
              backgroundColor="rgba(68, 176, 126, 0.8)"
              h="10px"
              w="10px"
              mr="5px"
            ></Box>
            <Text color="rgba(37, 39, 51, 0.6)" fontSize={14} fontWeight={500}>
              % of Covid Form
            </Text>
          </Flex>
          <Flex alignItems="center">
            <Box
              backgroundColor="rgba(14, 98, 255, 0.8)"
              h="10px"
              w="10px"
              mr="5px"
            ></Box>
            <Text color="rgba(37, 39, 51, 0.6)" fontSize={14} fontWeight={500}>
              % of Population Data (2015)
            </Text>
          </Flex>
        </HStack>
      </Flex>
      <Box p="0 40px 20px 20px">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            margin={{
              top: 40,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              strokeDasharray="1 1"
              stroke="#ddd"
              vertical={false}
            />
            <XAxis dataKey="range" tick={{fontSize: 12}} />
            <YAxis strokeWidth={0} tickFormatter={toPercent} tick={{fontSize: 12}}/>
            {/* <Tooltip /> */}
            <Bar
              name="Covid Form"
              dataKey="formPercentile"
              fill="rgba(68, 176, 126, 0.8)"
              barSize={30}
              radius={[5, 5, 0, 0]}
            />
            <Bar
              name="Population Data (2015)"
              dataKey="popPercentile"
              fill="rgba(14, 98, 255, 0.8)"
              barSize={30}
              radius={[5, 5, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
        <Text textAlign="center" color="#0E62FF" fontSize={11} fontWeight={500}>Age Group</Text> 
      </Box>
    </Box>
  );
};

export default AgeGroupChart;
