import React, { Component } from 'react';

import moment from 'moment';
import { GoogleApiWrapper } from 'google-maps-react';

import { baseUrl, version } from '../url';
import Modal from '../modal/Modal';
import PersonalSection from './PersonalSection';
import MoreDetailsSection from './MoreDetailsSection';
import SymptomsSection from './SymptomsSection';
import MedicalSection from './MedicalSection';
import CaseContactSection from './CaseContactSection';
import { apolloQuery } from '../helpers';
// import ConsentSection from './ConsentSection';

import 'react-intl-tel-input/dist/main.css';

import success_icon from '../../assets/submitted-icon.svg';
import repeat_icon from '../../assets/repeat-icon.svg';
import logo from '../../assets/nimr-logo.png';
import cchub_logo from '../../assets/cchub.png';
import step_check from '../../assets/step-check.svg';

const isProd = process.env.NODE_ENV === 'production';

const initialState = {
    title: '',
    marital_status: '',
    sex: '',
    first_name: '',
    last_name: '',
    dob: '',
    address: '',
    phone_no: '',
    email: '',
    nationality: '',
    countries_visited: '',
    countries_list: [{name: 'Nigeria'}],
    show: false,
    showError: false,
    errorModal: false,
    entry: {},
    noResponse: false,
    errorMessage: '',
    showContactForm: false,
    contact_first_name: '',
    contact_last_name: '',
    contact_phone_no: '',
    contact_relation: '',
    contacts: [],
    contactId: null,
    symptoms: [],
    other_symptoms: '',
    travelled: '',
    capchaValue: '',
    health_facility: '',
    suspected_case: '',
    mass_gathering: '',
    travellers: '',
    conditions: [],
    other_conditions: '',
    transport_mode: '',
    consent: null,
    consentModal: false,
    vaccine: '',

    vaccineTypes: [],
    vaccinated: '',
    vaccineType: '',
    numberOfDoses: '',
    dateOfFirstDose: '',
    dateOfSecondDose: '',
    step: 1
}
class Form extends Component {
    constructor() {
        super()
        this.state = {
            ...initialState
        }

        this.placesDiv = React.createRef();
        this.recaptchaRef = React.createRef();
    }

    componentDidMount() {
        this.fetchCountriesList();
        this.getVaccinesTypes();
    }

    hideErrorModal = () => {
        this.setState({ showError: false })
    }

    showConsentModal = () => {
        this.setState({ consentModal: true })
    }

    hideConsentModal = () => {
        this.setState({ consentModal: false })
    }

    handleAddress = address => {
        this.setState({
            address,
            errorMessage: '',
        });
    };

    getVaccinesTypes = async () => {
        const query = `{
            vaccines{
                ...on Vaccines{
                    status
                    data
                }
            }
        }
        `
          const response = await apolloQuery(query);
          const vaccineTypes = response.vaccines.data;
            this.setState({
                vaccineTypes,
            })
    }

    handlePhoneNumber = (isValid, value, countryData, number) => {
        const phone_no = number.replace(/\s/g, '');
        this.setState({
            phone_no,
        }, () => {
            if (this.state.incompleteFields) {
                this.checkFields();
            }
        })
    }

    handleReCapcha = value => {
        this.setState({
            capchaValue: value
        }, () => {
            if (this.state.incompleteFields) {
                this.checkFields();
            }
        });
    };

    handleContactPhoneNumber = (isValid, value, countryData, number) => {
        const phone_no = number.replace(/\s/g, '');
        this.setState({
            contact_phone_no: phone_no,
        })
    }

    handleOtherSymptoms = () => {
        const { symptoms, other_symptoms } = this.state;
        if (other_symptoms.length > 0) {
            const otherSymptoms = this.stringToArray(other_symptoms);
            return [...symptoms, ...otherSymptoms]
        }
        return [...symptoms]
    }

    handleSymptoms = (symptom) => {
        const { symptoms } = this.state;
        const exists = symptoms.includes(symptom);
        if (exists) {
            const index = symptoms.indexOf(symptom);
            const newSymptoms = symptoms;
            newSymptoms.splice(index, 1);
            this.setState({
                symptoms: [...newSymptoms],
                noSymptom: symptom==="No Symptoms" ? false : this.state.noSymptom
            })
        } else {
            if(symptom==="No Symptoms"){
                this.setState({
                    symptoms: [symptom],
                    other_symptoms: '',
                    noSymptom: true
                })
            } else {
                this.setState({
                    symptoms: [...symptoms, symptom]
                })
            }
        }
    }

    handleSymptomsStyle = (symptom) => {
        const { symptoms } = this.state;
        const exists = symptoms.includes(symptom);
        return exists
    }


    handleOtherConditions = () => {
        const { conditions, other_conditions } = this.state;
        if (other_conditions.length > 0) {
            const otherConditions = this.stringToArray(other_conditions);
            return [...conditions, ...otherConditions]
        }
        return [...conditions]
    }

    handleConditions = (condition) => {
        const { conditions } = this.state;
        const exists = conditions.includes(condition);
        if (exists) {
            const index = conditions.indexOf(condition);
            const newConditions = conditions;
            newConditions.splice(index, 1);
            this.setState({
                conditions: [...newConditions]
            })
        } else {
            this.setState({
                conditions: [...conditions, condition]
            })
        }
    }

    handleConditionsStyle = (condition) => {
        const { conditions } = this.state;
        const exists = conditions.includes(condition);
        return exists
    }

    fetchCountriesList = () => {
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        const countriesUrl = "https://restcountries.eu/rest/v2/all?fields=name";
        fetch(proxyurl + countriesUrl)
            .then(response => response.json())
            .then(responseJson => {
                this.setState({
                    countries_list: responseJson
                })
            })
    }

    toggleContactForm = () => {
        this.setState({
            showContactForm: !this.state.showContactForm,
            contact_first_name: '',
            contact_last_name: '',
            contact_phone_no: '',
            contact_relation: '',
            contactId: null,
        })
    }

    handleSelect = selected => {
        this.setState({ address: selected }, () => {
            if (this.state.incompleteFields) {
                this.checkFields();
            }
        });
    };

    handleError = (status, clearSuggestions) => {
        console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            phone_noError: '',
        }, () => {
            if (this.state.incompleteFields) {
                this.checkFields();
            }
        })
    }

    handleDOB = (dob) => {
        this.setState({
            dob
        }, () => {
            if (this.state.incompleteFields) {
                this.checkFields();
            }
        })
    }

    saveCaseContact = (e) => {
        e.preventDefault();
        const {
            contact_first_name,
            contact_last_name,
            contact_phone_no,
            contact_relation,
            contactId
        } = this.state;
        let entry = {};
        if (contact_first_name) entry = { ...entry, first_name: contact_first_name };
        if (contact_last_name) entry = { ...entry, last_name: contact_last_name };
        if (contact_phone_no) entry = { ...entry, phone_no: contact_phone_no };
        if (contact_relation) entry = { ...entry, relation_to_case: contact_relation };
        if (contactId === null) {
            this.setState({
                contacts: [...this.state.contacts, entry],
            })
        } else {
            const { contacts } = this.state;
            const newContacts = contacts;
            newContacts[contactId] = entry;
            this.setState({
                contacts: [...newContacts],
            })
        }
        this.setState({
            contact_first_name: '',
            contact_last_name: '',
            contact_phone_no: '',
            contact_relation: '',
            showContactForm: false,
        })
    }

    selectContact = (id) => {
        const { contacts } = this.state;
        const contact = contacts.filter((entry, index) => id === index);
        const { first_name, last_name, phone_no, relation_to_case } = contact[0];
        this.setState({
            contactId: id,
            contact_first_name: first_name,
            contact_last_name: last_name,
            contact_phone_no: phone_no,
            contact_relation: relation_to_case,
            showContactForm: true,
        })
    }

    checkFields = () => {
        let incompleteFields;
        const {
            title,
            marital_status,
            sex,
            first_name,
            last_name,
            dob,
            address,
            phone_no,
            nationality,
            capchaValue,
            health_facility,
            suspected_case,
            mass_gathering,
            travellers,
            email,
            consent,
            transport_mode,
            vaccinated,
            vaccineType,
            numberOfDoses,
            dateOfFirstDose,
            dateOfSecondDose
        } = this.state;
        if (
            title &&
            marital_status &&
            sex &&
            first_name &&
            last_name &&
            dob &&
            address &&
            phone_no &&
            nationality &&
            // (isProd && capchaValue) &&
            health_facility &&
            suspected_case &&
            mass_gathering &&
            travellers &&
            email &&
            consent !== null &&
            transport_mode
        ) {
            incompleteFields = false;
        }
        else if(
            vaccinated==="yes" && 
            !vaccineType &&
            !numberOfDoses &&
            !dateOfFirstDose &&
            !dateOfSecondDose
        ){
            incompleteFields = true;
        }
        else {
            incompleteFields = true;
        }
        this.setState({
            incompleteFields,
            error: incompleteFields? 'You missed some fields.' : ''
        })
        return incompleteFields;
    }

    validateStepOne = () => {
        const {
            title,
            marital_status,
            sex,
            first_name,
            last_name,
            dob
        } = this.state;
        if (
            title &&
            marital_status &&
            sex &&
            first_name &&
            last_name &&
            dob
        ) {
            this.setState({
                incompleteFields: false,
                error: ''
            }, () => this.handleSteps(2))
            return false
        }
        else {
            this.setState({
                incompleteFields: true,
                stepOneError: 'You missed some fields.'
            })
            return true
        }
    }

    validateStepTwo = () => {
        let incompleteFields;
        const {
            address,
            phone_no,
            nationality,
            email,
            vaccinated,
            vaccineType,
            numberOfDoses,
            dateOfFirstDose
        } = this.state;
        if (
            address &&
            phone_no &&
            nationality &&
            email &&
            vaccinated==="no"
        ) {
            incompleteFields = false
        }
        else {
            if(
                vaccinated==="yes" && 
                vaccineType &&
                numberOfDoses &&
                dateOfFirstDose
            ){
                incompleteFields = false;
            } else {
                incompleteFields = true
            }
        }
        this.setState({
            incompleteFields,
            error: incompleteFields? 'You missed some fields.' : ''
        }, () => {!incompleteFields && this.handleSteps(3)});

        return incompleteFields
    }

    validateStepFour = () => {
        const {
            health_facility,
            suspected_case,
            mass_gathering,
            travellers,
        } = this.state;
        if (
            health_facility &&
            suspected_case &&
            mass_gathering &&
            travellers
        ) {
            this.setState({
                incompleteFields: false,
                error: ''
            }, () => this.handleSteps(5))
            return false
        }
        else {
            this.setState({
                incompleteFields: true,
                stepFourError: 'You missed some fields.'
            })
            return true
        }
    }

    validateStepFive = () => {
        const {
            consent,
            transport_mode,
            // capchaValue,
        } = this.state;
        if (
            consent !== null &&
            transport_mode
            //&& capchaValue
        ) {
            this.setState({
                incompleteFields: false,
                error: ''
            }, () => this.submit())
            return false
        }
        else {
            this.setState({
                incompleteFields: true,
                stepFourError: 'You missed some fields.'
            })
            return true
        }
    }

    handleSteps = (step) => {
        this.setState({
            step,
        }, () => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.ensureActiveItemVisible(step);
        })
    }

    ensureActiveItemVisible = (step) => {
        var scrollItem = this.refs.scrollSteps;
        var activeItem = this.refs.activeItem;
        var scrollOffset = activeItem.offsetLeft
        if (scrollItem) {
            scrollItem.scrollLeft = scrollOffset - 100;
        }
    }

    submit = () => {
        this.checkFields();
        const errors = this.checkFields();
        if (!errors) {
            this.setState({
                noResponse: true,
            })
            const {
                first_name,
                last_name,
                phone_no,
                title,
                sex,
                dob,
                marital_status,
                address,
                email,
                contacts,
                nationality,
                countries_visited,
                health_facility,
                suspected_case,
                mass_gathering,
                travellers,
                transport_mode,
                consent,
                vaccine,
                vaccinated,
                vaccineType,
                numberOfDoses,
                dateOfFirstDose,
                dateOfSecondDose
            } = this.state;
            let entry = {
                first_name,
                last_name,
                phone_no,
                title,
                gender: sex,
                dob: moment(dob, 'YYYY-MM-D').format('YYYY-MM-DD'),
                marital_status,
                home_address: address,
                date_of_enrollment: moment().format('YYYY-MM-DD'),
                contacts,
                nationality,
                countries_visited: countries_visited.length > 0 ? this.stringToArray(countries_visited) : [],
                symptoms: this.handleOtherSymptoms(),
                employed_by_cases_management_facility: health_facility,
                attended_mass_gathering: mass_gathering,
                contact_with_overseas_traveller: travellers,
                contact_with_suspect: suspected_case,
                qualified_case: this.checkEligibility(),
                preExistingConditions: this.handleOtherConditions(),
                mode_of_transportation: transport_mode,
                consent,
                bcg_vaccine: vaccine,
                vaccinated,
            };

            if (email) entry = { ...entry, email }
            if (vaccineType) entry = { ...entry, vaccineType };
            if (numberOfDoses) entry = { ...entry, numberOfDoses: Number(numberOfDoses) };
            if (dateOfFirstDose) entry = { ...entry, dateOfFirstDose: moment(dateOfFirstDose, 'YYYY-MM-D').format('YYYY-MM-DD') };
            if (dateOfSecondDose) entry = { ...entry, dateOfSecondDose: moment(dateOfSecondDose, 'YYYY-MM-D').format('YYYY-MM-DD') };
            
            this.sendData(entry)
        }
    }

    sendData = (entry) => {
        fetch(`${baseUrl}/${version}/cases/register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'credentials': 'include'
            },
            body: JSON.stringify(entry)
        })
            .then((response) => {
                this.setState({
                    noResponse: false,
                })
                if (response.status === 403) {
                    this.setState({
                        error: "You are not allowed to perform this action.",
                        errorModal: true,
                    })
                }
                return response.json();
            })
            .then((responseJson) => {
                if (responseJson.status === 200 || responseJson.status === 201) {
                    this.setState({
                        show: true,
                    })
                }
                if (responseJson.hasOwnProperty('error')) {
                    if (responseJson.status === 400) {
                        this.setState({
                            showError: true,
                            serverError: responseJson.error,
                        })
                    }
                    else {
                        this.setState({
                            error: responseJson.error
                        })
                    }
                }
            })
            .catch((error) => {
            })
    }

    handleReset = () => {
        window.location.reload();
        window.scrollTo(0, 0);
    }

    stringToArray = (string) => {
        const convertedArray = string.split(",");
        const trimmedArray = convertedArray.map(el => el.trim());
        return trimmedArray;
    }

    handleOptions = (field, value) => {
        this.setState({
            [field]: value,
        }, () => {
            if (this.state.incompleteFields) {
                this.checkFields();
            }
        })
    }

    checkEligibility = () => {
        const { health_facility, suspected_case, mass_gathering, travellers } = this.state;
        if (
            health_facility === 'yes' ||
            suspected_case === 'yes' ||
            mass_gathering === 'yes' ||
            travellers === 'yes' ||
            this.handleOtherSymptoms().length > 0
        ) {
            return 'yes'
        } else {
            return 'no'
        }
    }
    render() {
        const {
            show,
            showError,
            countries_list,
            serverError,
            error,
            step,
            noResponse,
            vaccineTypes
        } = this.state;
        let countryOption;
        if (countries_list.length > 0) {
            countryOption = countries_list.map((country, index) =>
                <option key={index}>{country.name}</option>
            )
        }
        let vaccineOption;
        if (vaccineTypes.length > 0) {
            vaccineOption = vaccineTypes.map((vaccine, index) =>
                <option key={index}>{vaccine}</option>
            )
        }
        return (
            <div className="enrollment-form">
                <div className="form-header">
                    <div className="nimr-logo">
                        <img src={logo} alt="" />
                    </div>
                </div>
                <div className="main-content">
                    <div className="steps desktop">
                        <div 
                            className={`step ${step === 1 ? 'active' : ''} ${step > 1 ? 'past' : ''}`}
                            onClick={step > 1? () => {this.handleSteps(1)} :  null}
                        >
                            01   Personal Details
                            <img src={step_check} alt=""/>
                        </div>
                        <div 
                            className={`step ${step === 2 ? 'active' : ''} ${step > 2 ? 'past' : ''}`}
                            onClick={step > 2? () => {this.handleSteps(2)} :  null}
                        >
                            02   More Details
                            <img src={step_check} alt=""/>
                        </div>
                        <div 
                            className={`step ${step === 3 ? 'active' : ''} ${step > 3 ? 'past' : ''}`}
                            onClick={step > 3? () => {this.handleSteps(3)} :  null}
                        >
                            03   Log Symptoms
                            <img src={step_check} alt=""/>
                        </div>
                        <div
                            className={`step ${step === 4 ? 'active' : ''} ${step > 4 ? 'past' : ''}`}
                            onClick={step > 4? () => {this.handleSteps(4)} :  null}
                        >
                            04   Medical Condition
                            <img src={step_check} alt=""/>
                        </div>
                        <div className={`step ${step === 5 ? 'active' : ''} ${step > 5 ? 'past' : ''}`}>
                            05   Case Contact
                            <img src={step_check} alt=""/>
                        </div>
                        <div className="logo-div">
                            <p>Powered by</p>
                            <img src={cchub_logo} alt="" className="cchub logo" />
                        </div>
                    </div>
                    <div className="steps-container">
                        <div className="steps mobile" ref="scrollSteps">
                            <div
                                className={`step ${step === 1 ? 'active' : ''} ${step > 1 ? 'past' : ''}`}
                                onClick={step > 1? () => {this.handleSteps(1)} :  null}
                                ref={step === 1 && "activeItem"}
                            >
                                01   Personal Details
                                <img src={step_check} alt=""/>
                            </div>
                            <div
                                className={`step ${step === 2 ? 'active' : ''} ${step > 2 ? 'past' : ''}`}
                                onClick={step > 1? () => {this.handleSteps(2)} :  null}
                                ref={step === 2 && "activeItem"}
                            >
                                02   More Details
                                <img src={step_check} alt=""/>
                            </div>
                            <div
                                className={`step ${step === 3 ? 'active' : ''} ${step > 3 ? 'past' : ''}`}
                                onClick={step > 1? () => {this.handleSteps(3)} :  null}
                                ref={step === 3 && "activeItem"}
                            >
                                03   Log Symptoms
                                <img src={step_check} alt=""/>
                            </div>
                            <div
                                className={`step ${step === 4 ? 'active' : ''} ${step > 4 ? 'past' : ''}`}
                                onClick={step > 1? () => {this.handleSteps(4)} :  null}
                                ref={step === 4 && "activeItem"}
                            >
                                04   Medical Condition
                                <img src={step_check} alt=""/>
                            </div>
                            <div
                                className={`step ${step === 5 ? 'active' : ''} ${step > 5 ? 'past' : ''}`}
                                ref={step === 5 && "activeItem"}
                            >
                                05   Case Contact
                                <img src={step_check} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div className="form-content">
                        {
                            step === 1 &&
                            <React.Fragment>
                                <h3 className="form-title mobile">Covid-19 Testing Program <br /> (Request Form)</h3>
                                <p className="description mobile">
                                    NIMR in partnership with <span>LifeBank</span> is conducting Drive-through
                                Test to make it easier for people to get tested and prevent the
                                spread of CoVid-19. Kindly register below to get tested at no cost.
                            </p>
                                <PersonalSection
                                    title={this.state.title}
                                    sex={this.state.sex}
                                    first_name={this.state.first_name}
                                    last_name={this.state.last_name}
                                    dob={this.state.dob}
                                    marital_status={this.state.marital_status}
                                    handleChange={this.handleChange}
                                    handleOptions={this.handleOptions}
                                    handleDOB={this.handleDOB}
                                    incompleteFields={this.state.incompleteFields}
                                    next={this.validateStepOne}
                                />
                            </React.Fragment>
                        }
                        {
                            step === 2 &&
                            <MoreDetailsSection
                                email={this.state.email}
                                countryOption={countryOption}
                                nationality={this.state.nationality}
                                address={this.state.address}
                                phone_no={this.state.phone_no}
                                vaccinated={this.state.vaccinated}
                                vaccineType={this.state.vaccineType}
                                numberOfDoses={this.state.numberOfDoses}
                                dateOfFirstDose={this.state.dateOfFirstDose}
                                dateOfSecondDose={this.state.dateOfSecondDose}
                                vaccineOption={vaccineOption}
                                handleError={this.handleError}
                                handleAddress={this.handleAddress}
                                handleSelect={this.handleSelect}
                                handlePhoneNumber={this.handlePhoneNumber}
                                handleChange={this.handleChange}
                                handleOptions={this.handleOptions}
                                incompleteFields={this.state.incompleteFields}
                                next={this.validateStepTwo}
                                back={() => this.handleSteps(1)}
                            />
                        }
                        {
                            step === 3 &&
                            <SymptomsSection
                                countries_visited={this.state.countries_visited}
                                travelled={this.state.travelled}
                                noSymptom={this.state.noSymptom}
                                otherSymptoms={this.state.other_symptoms}
                                handleOptions={this.handleOptions}
                                handleChange={this.handleChange}
                                handleSymptoms={this.handleSymptoms}
                                handleSymptomsStyle={this.handleSymptomsStyle}
                                next={() => this.handleSteps(4)}
                                back={() => this.handleSteps(2)}
                            />
                        }
                        {
                            step === 4 &&
                            <MedicalSection
                                suspected_case={this.state.suspected_case}
                                health_facility={this.state.health_facility}
                                mass_gathering={this.state.mass_gathering}
                                travellers={this.state.travellers}
                                vaccine={this.state.vaccine}
                                handleChange={this.handleChange}
                                handleOptions={this.handleOptions}
                                handleConditionsStyle={this.handleConditionsStyle}
                                handleConditions={this.handleConditions}
                                incompleteFields={this.state.incompleteFields}
                                next={this.validateStepFour}
                                back={() => this.handleSteps(3)}
                            />
                        }
                        {
                            step === 5 &&
                            <CaseContactSection
                                contact_first_name={this.state.contact_first_name}
                                contact_last_name={this.state.contact_last_name}
                                contact_relation={this.state.contact_relation}
                                transport_mode={this.state.transport_mode}
                                showContactForm={this.state.showContactForm}
                                contacts={this.state.contacts}
                                handleChange={this.handleChange}
                                toggleContactForm={this.toggleContactForm}
                                handleOptions={this.handleOptions}
                                saveCaseContact={this.saveCaseContact}
                                selectContact={this.selectContact}
                                handleContactPhoneNumber={this.handleContactPhoneNumber}
                                incompleteFields={this.state.incompleteFields}
                                consent={this.state.consent}
                                consentModal={this.state.consentModal}
                                showConsentModal={this.showConsentModal}
                                hideConsentModal={this.hideConsentModal}
                                captchaRef={this.recaptchaRef}
                                sitekey="6LeUuOQUAAAAAMjVF4r2w-uU2JAPvF4JXEfau8jX"
                                handleReCapcha={this.handleReCapcha}
                                error={error}
                                submit={this.validateStepFive}
                                back={() => this.handleSteps(4)}
                                noResponse={noResponse}
                                capchaValue={this.state.capchaValue}
                            />
                        }
                    </div>
                    <div className="logo-div mobile">
                        <p>Powered by</p>
                        <img src={cchub_logo} alt="" className="cchub logo" />
                    </div>
                </div>
                <div ref={this.placesDiv}></div>
                <Modal show={show}>
                    <div className="success-div">
                        <img src={success_icon} alt="" className="success_icon" />
                        <h4>Request Submitted</h4>
                        <p>If you're are considered to be at risk, you will receive an invitation email
                            with your unique case number from NIMR to be tested.</p>
                        <button onClick={this.handleReset}>Done</button>
                    </div>
                </Modal>
                <Modal show={showError}>
                    <div className="success-div">
                        <img src={repeat_icon} alt="" className="success_icon" />
                        {
                            serverError === "Case already exists." ?
                                <React.Fragment>
                                    <h4>Repeat Entry</h4>
                                    <p>
                                        Looks like you’ve made a request. We're working on it and you will be
                                        contacted if you are considered to be at risk.
                                </p>
                                </React.Fragment>
                                :
                                <h4>{serverError}</h4>
                        }
                        <button onClick={this.hideErrorModal}>Done</button>
                    </div>
                </Modal>
            </div>
        )
    }
}

const Loader = () => (
    <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "25px",
        height: '100vh',
        background: '#F4F7FA'
    }}>
        <i style={{
            fontSize: "36px",
            color: "#276DF7"
        }}
            className="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
)

export default
    GoogleApiWrapper({
        apiKey: 'AIzaSyBSYFtIecZaXi9MBN65ZOrBB6DcreYFghg',
        LoadingContainer: Loader,
    })(Form);
