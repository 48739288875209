import React, { useState } from "react";
import {
  Input,
  FormControl,
  Flex,
  FormLabel,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

import { ShowPasswordIcon, HidePasswordIcon } from "../Icons";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import Calendar from "../newAssets/svg/calendar.svg";
// import moment from "moment";

export const TextInput = ({
  placeholder,
  label,
  handleInput,
  value,
  type = "text",
  disabled,
}) => {
  const [inputType, setInputType] = useState(type);
  return (
    <FormControl>
      <FormLabel
        padding={0}
        // htmlFor="email"
        color="#827F98"
        // fontWeight={500}
        fontSize={16}
        mb="15px"
      >
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          focusBorderColor="none"
          _placeholder={{ color: "#ddd", fontWeight: 400 }}
          color="#333333"
          fontWeight={600}
          fontSize={18}
          value={value}
          onChange={(e) => handleInput(e.target.value)}
          variant="unstyled"
          placeholder={placeholder}
          type={inputType}
          height="55px"
          border="1px solid #DBDAE3"
          borderRadius={4}
          paddingX={4}
          background="#F6F6F6"
          disabled={disabled}
          pr="4rem"
        />
        {type === "password" && (
          <InputRightElement width="4rem" height="55px">
          <Flex justifyContent="center" alignItems="center">
            {inputType === "password" ? (
              <ShowPasswordIcon triggerAction={() => setInputType("text")} />
            ) : (
              <HidePasswordIcon
                triggerAction={() => setInputType("password")}
              />
            )}
            </Flex>
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
};

// export const PasswordInput = ({ placeholder, label, handleInput, value }) => {
//   const [type, settype] = useState("password");
//   return (
//     <FormControl
//       borderColor="#ddd"
//       borderStyle="solid"
//       borderWidth={1}
//       borderRadius={10}
//       paddingY={2}
//       paddingX={4}
//     >
//       <Flex justifyContent="space-between" alignItems="center">
//         <Box width="90%">
//           <FormLabel
//             margin={0}
//             padding={0}
//             htmlFor="email"
//             color="#616164"
//             fontWeight={400}
//             fontSize={15}
//           >
//             {label}
//           </FormLabel>
//           <Input
//             focusBorderColor="none"
//             _placeholder={{ color: "#ddd", fontWeight: 400 }}
//             fontWeight={600}
//             fontSize={18}
//             value={value}
//             onChange={(e) => handleInput(e.target.value)}
//             variant="unstyled"
//             placeholder={placeholder}
//             border="none"
//             type={type}
//           />
//         </Box>
//         <Button
//           variant="unstyled"
//           onClick={() => settype(type === "password" ? "text" : "password")}
//         >
//           {type === "password" ? "Show" : "Hide"}
//         </Button>
//       </Flex>
//     </FormControl>
//   );
// };

// export const DateInput = ({ placeholder, label, handleInput, value }) => {
//   const [startDate, setStartDate] = useState(new Date());
//   return (
//     <FormControl
//       borderColor="#ddd"
//       borderStyle="solid"
//       borderWidth={1}
//       borderRadius={10}
//       paddingY={2}
//       paddingX={4}
//     >
//       <Flex justifyContent="space-between">
//         <Box>
//           <FormLabel
//             margin={0}
//             padding={0}
//             htmlFor="email"
//             color="#616164"
//             fontWeight={400}
//             fontSize={15}
//           >
//             {label}
//           </FormLabel>
//           <DatePicker
//             selected={startDate}
//             onChange={(date) => {
//               handleInput(date);
//               setStartDate(date);
//             }}
//             value={value ? moment(value).format("DD MMM YYYY") : ''}
//             dateFormat="dd MMM yyyy"
//             placeholderText={placeholder}
//             showMonthDropdown
//             showYearDropdown
//             dropdownMode="select"
//           />
//         </Box>
//         <Image src={Calendar} />
//       </Flex>
//     </FormControl>
//   );
// };
