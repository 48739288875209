import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelList,
} from "recharts";

const BCGChart = ({ data }) => {
  const CustomLabel = (props) => {
  const { y, value, viewBox: { width } } = props;

  return (
    <text x={width - 30} y={y} dy={23} fill='white' fontSize={14} textAnchor="middle">{value}%</text>
  )
};
  const toPercent = (value) => {
    return `${value}%`;
  };
  return (
    <Box
      bg="#FFFFFF"
      border="1px solid rgba(223, 224, 235, 0.7)"
      borderRadius="8px"
    >
      <Flex
        px="30px"
        py="22px"
        borderBottom="1.5px solid rgba(223, 224, 235, 0.5)"
        // justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Text color="rgba(37, 39, 51, 0.85)" fontSize={16}>
          Applicants who Have Recieved BGC Vaccine 
        </Text>
      </Flex>
      <Box p="0 40px 20px 20px">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            margin={{
              top: 40,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            layout="vertical"
            stackOffset="expand"
          >
            <CartesianGrid
              strokeDasharray="1 1"
              stroke="#ddd"
              vertical={false}
            />
            <XAxis type="number" tickFormatter={toPercent} tick={{fontSize: 12}} />
            <YAxis type="category" strokeWidth={0} dataKey="name" hide />
            <Bar
              name="Number of Request"
              dataKey="percentile"
              fill="rgba(14, 98, 255, 0.8)"
              barSize={36}
              radius={[0, 5, 5, 0]}
            >
              <LabelList
                dataKey="status"
                position="insideLeft"
                style={{ fill: "white" }}
              />
              <LabelList
                dataKey="percentile"
                position="insideRight"
                style={{ fill: "white" }}
                content={<CustomLabel />}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Text textAlign="center" color="#0E62FF" fontSize={11} fontWeight={500}>Percentage</Text> 
      </Box>
    </Box>
  );
};

export default BCGChart;
