import React from "react";
import { Button } from "@chakra-ui/react";

export const ButtonComponent = ({ triggerAction, buttonText, isLoading }) => {
  return (
    <Button
      background="#276DF7"
      borderRadius="5px"
      width="150px"
      height="45px"
      mx="auto"
      color="#FFFFFF"
      fontSize={16}
      fontWeight={700}
      onClick={triggerAction}
      _focus=""
      _hover=""
      _active=""
      isLoading={isLoading}
    >
      {buttonText}
    </Button>
  );
};
