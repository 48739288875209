import React from 'react';

class Modal extends React.Component{
    render(){
        const showHideClassname = this.props.show ? "modal display-block" : "modal display-none";
        return(
            <div className={showHideClassname}>
                <section className="modal-main">
                    {this.props.children}
                </section>
            </div>
        )
    }
}

export default Modal;