import React, { Component } from 'react';
import CaseList from './CaseList';
import Header from '../Header';

import wavy from '../../assets/waves.png';

export default class Dasboard extends Component {
    render() {
        return (
            <React.Fragment>
                <Header page="dashboard"/>
                <div className="dashboard">
                    <div className="waves">
                        <img src={wavy} alt="" />
                    </div>
                    <CaseList />
                </div>
            </React.Fragment>
        )
    }
}
