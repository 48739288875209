import React, { Component } from 'react';
import moment from 'moment';

export default class PrintOut extends Component {
    render() {
        const { case_number, initials } = this.props
        return (
            <table>
                <tbody style={{ fontSize: "8px" }}>
                    <tr>
                        <td>Case No: </td>
                        <td><b>{case_number}</b></td>
                    </tr>
                    <tr>
                        <td>Initials: </td>
                        <td><b>{initials}</b></td>
                    </tr>
                    <tr>
                        <td>Date: </td>
                        <td><b>{moment().format('DD/MM/YYYY')}</b></td>
                    </tr>
                </tbody>
            </table>
        )
    }
}