import React, { Component } from "react";
import IntlTelInput from "react-intl-tel-input";
import GoogleAddress from "./GoogleAddress";

export default class MoreDetailsSection extends Component {
  render() {
    const {
      email,
      address,
      nationality,
      phone_no,
      vaccinated,
      vaccineType,
      numberOfDoses,
      dateOfFirstDose,
      dateOfSecondDose,
      vaccineOption,
      handleOptions,
      handlePhoneNumber,
      handleChange,
      countryOption,
      handleAddress,
      handleSelect,
      handleError,
      incompleteFields,
      next,
      back,
      // error
    } = this.props;
    return (
      <div className="form-sections">
        <h4 className="section-title mobile">More Details</h4>
        <div className="section-content">
          <h4 className="section-title desktop">More Details</h4>
          <label className={incompleteFields && !email ? "error" : ""}>
            <span className="star">*</span>Email
            <input
              type="email"
              name="email"
              placeholder="Enter email address"
              value={email}
              onChange={handleChange}
            />
          </label>
          <label className={incompleteFields && !phone_no ? "error" : ""}>
            <span className="star">*</span>Phone Number
            <IntlTelInput
              preferredCountries={["ng"]}
              separateDialCode={true}
              useMobileFullscreenDropdown={false}
              formatOnInit={false}
              onPhoneNumberChange={handlePhoneNumber}
            />
          </label>
          <GoogleAddress
            address={address}
            incompleteFields={incompleteFields}
            handleAddress={handleAddress}
            handleSelect={handleSelect}
            handleError={handleError}
          />
          <label className={incompleteFields && !nationality ? "error" : ""}>
            <span className="star">*</span>Nationality
            <select
              className="nationality"
              name="nationality"
              value={nationality}
              onChange={handleChange}
            >
              <option>Select country&#160;&#160;&#160;</option>
              {countryOption}
            </select>
          </label>
          <label>
            <span className="star">*</span>Have you received the Covid-19 vaccine?
            <div className="input-options two">
              <div
                className={`option ${vaccinated === "yes" ? "selected" : ""}`}
                onClick={() => handleOptions("vaccinated", "yes")}
              >
                Yes
              </div>
              <div
                className={`option ${vaccinated === "no" ? "selected" : ""}`}
                onClick={() => handleOptions("vaccinated", "no")}
              >
                No
              </div>
            </div>
          </label>
          {vaccinated === "yes" && (
            <React.Fragment>
              <label
                className={incompleteFields && !vaccineType ? "error" : ""}
              >
                <span className="star">*</span>Vaccine Type
                <select
                  name="vaccineType"
                  value={vaccineType}
                  onChange={handleChange}
                >
                  <option>Select vaccine type&#160;&#160;&#160;</option>
                  {vaccineOption}
                </select>
              </label>
              <label
                className={incompleteFields && !numberOfDoses ? "error" : ""}
              >
                <span className="star">*</span>Number of Dose(s) Gotten
                <select
                  name="numberOfDoses"
                  value={numberOfDoses}
                  onChange={handleChange}
                >
                  <option>
                    Select number of dose(s) gotten&#160;&#160;&#160;
                  </option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                </select>
              </label>
                {
                  (numberOfDoses === '1' || numberOfDoses === '2') &&
                  <label
                    className={`dob-label ${
                      incompleteFields && !dateOfFirstDose ? "error" : ""
                    }`}
                  >
                    <span className="star">*</span>Date of First Dose
                    <input
                      type="date"
                      name="dateOfFirstDose"
                      placeholder="dd-mm-yy"
                      value={dateOfFirstDose}
                      onChange={handleChange}
                      className="date-input"
                    />
                  </label>
                }
                {
                  numberOfDoses === '2' &&
                  <label
                    className={`dob-label ${
                      incompleteFields && !dateOfSecondDose ? "error" : ""
                    }`}
                  >
                    <span className="star">*</span>Date of Second Dose
                    <input
                      type="date"
                      name="dateOfSecondDose"
                      placeholder="dd-mm-yy"
                      value={dateOfSecondDose}
                      onChange={handleChange}
                      className="date-input"
                    />
                  </label>
                }
            </React.Fragment>
          )}
          {/* {
            error &&
            <p className="error-text">
              <i className="fa fa-exclamation-circle" />
              {error}
            </p>
          } */}
          <div className="button-section">
            <button onClick={back}>Back</button>
            <button className="right-button" onClick={next}>
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}
