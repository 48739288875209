import React, { Component } from 'react'

export default class MedicalSection extends Component {
  render() {
    const {
      handleChange,
      suspected_case,
      health_facility,
      mass_gathering,
      travellers,
      vaccine,
      handleOptions,
      handleConditions,
      handleConditionsStyle,
      incompleteFields,
      next,
      back,
      // error
    } = this.props;

    const conditions = [
      'Pregnancy',
      'Obesity',
      'Heart disease',
      'Asthma requiring medication',
      'Chronic lung disease (No asthma)',
      'Chronic liver disease',
      'Chronic hematological disorder',
      'Chronic kidney disease',
      'Chronic Neurological impairment/disease',
      'Organ or bone marrow recipient',
      'Sickle cell disease',
      'Type 2 diabetes',
      'Cancer',
      'Ulcer'
    ]

    return (
      <div className="form-sections">
        <h4 className="section-title mobile">Medical Condition</h4>
        <div className="section-content">
        <h4 className="section-title desktop">Medical Condition</h4>
          <label>
            Do you have any of the following pre-existing conditions?
            <div className="options-list">
              {
                conditions.map(condition => (
                  <div
                    className={handleConditionsStyle(condition) ? "option selected" : "option"}
                    onClick={() => handleConditions(condition)}>
                    <p>{condition}</p>
                    <div className="check">
                      <i className="fa fa-check-circle" />
                    </div>
                  </div>
                ))
              }
            </div>
          </label>
          <label>
            <textarea
              name="other_conditions"
              placeholder="Other pre-existing condition(s)"
              onChange={handleChange}
            />
          </label>
          <label className={incompleteFields && !suspected_case? 'error' : ''}>
            <span className="star">*</span>Have you had contact with a suspected or confirmed Covid-19 patient?
            <div className="input-options two">
              <div className={`option ${suspected_case === "yes" ? 'selected' : ''}`}
                onClick={() => handleOptions('suspected_case', 'yes')}>
                Yes
              </div>
              <div className={`option ${suspected_case === "no" ? 'selected' : ''}`}
                onClick={() => handleOptions('suspected_case', 'no')}>
                No
              </div>
            </div>
          </label>
          <label className={incompleteFields && !health_facility? 'error' : ''}>
            <span className="star">*</span>Have you been to any organizations where a Covid-19 case was confirmed or managed?
              <div className="input-options two">
              <div className={`option ${health_facility === "yes" ? 'selected' : ''}`}
                onClick={() => handleOptions('health_facility', 'yes')}>
                Yes
              </div>
              <div className={`option ${health_facility === "no" ? 'selected' : ''}`}
                onClick={() => handleOptions('health_facility', 'no')}>
                No
              </div>
            </div>
          </label>
          <label className={incompleteFields && !mass_gathering? 'error' : ''}>
              <span className="star">*</span>Have you been to any mass gathering recently?
              <div className="input-options two">
                  <div className={`option ${mass_gathering === "yes" ? 'selected' : ''}`}
                      onClick={() => handleOptions('mass_gathering', 'yes')}>
                      Yes
                  </div>
                  <div className={`option ${mass_gathering === "no" ? 'selected' : ''}`}
                      onClick={() => handleOptions('mass_gathering', 'no')}>
                      No
                  </div>
              </div>
          </label>
          <label className={incompleteFields && !travellers? 'error' : ''}>
              <span className="star">*</span>Have you had contact with travellers who have recently been overseas in the last 14 days?
              <div className="input-options two">
                  <div className={`option ${travellers === "yes" ? 'selected' : ''}`}
                      onClick={() => handleOptions('travellers', 'yes')}>
                      Yes
                  </div>
                  <div className={`option ${travellers === "no" ? 'selected' : ''}`}
                      onClick={() => handleOptions('travellers', 'no')}>
                      No
                  </div>
              </div>
          </label>
          <label className={incompleteFields && !vaccine? 'error' : ''}>
            <span className="star">*</span>Have you ever had the BCG vaccine against Tuberculosis? 
            (Note: The vaccine usually leaves a scar on the right or left upper arm.)
            <div className="input-options three">
                <div className={`option ${vaccine === "yes" ? 'selected' : ''}`}
                    onClick={() => handleOptions('vaccine', 'yes')}>
                    Yes
                </div>
                <div className={`option ${vaccine === "no" ? 'selected' : ''}`}
                    onClick={() => handleOptions('vaccine', 'no')}>
                    No
                </div>
                <div className={`option ${vaccine === "not sure" ? 'selected' : ''}`}
                    onClick={() => handleOptions('vaccine', 'not sure')}>
                    Not Sure
                </div>
            </div>
          </label>
          {/* {
            error &&
            <p className="error-text">
              <i className="fa fa-exclamation-circle" />
              {error}
            </p>
          } */}
          <div className="button-section">
            <button onClick={back}>Back</button>
            <button className="right-button" onClick={next}>Next</button>
          </div>
        </div>
      </div>
    )
  }
}
