import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import logo from "../../assets/nimr-logo.svg";

class Header extends Component {
//   state = {
//     account: null,
//   };
  render() {
    const { page="verify" } = this.props;
    return (
      <div className="header">
        <img src={logo} alt="" />
        <div className="nav-links no-mobile">
          <div
            className={`nav-link${page === "verify" ? " active" : ""}`}
            onClick={this.caseList}
          >
            <span>Verify Result</span>
            <div className="bar"></div>
          </div>
        </div>
        <p className="no-mobile">Contact Tracing Form</p>
      </div>
    );
  }
}

export default withRouter(Header);
