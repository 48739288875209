import React, { useState, useEffect } from "react";
import AccountLayout from "./AccountLayout";
import { Box, Text, Stack } from "@chakra-ui/react";

import { TextInput } from "./Input";
import { baseUrl, version } from "../url";

const Details = () => {
  const [account, setAccount] = useState({});

  useEffect(() => {
    fetchAccount();
  }, [])

  const fetchAccount = () => {
    let accountDetails = JSON.parse(localStorage.getItem("account"));

    if (!accountDetails) {
      const token = `Bearer ${localStorage.getItem("access_token")}`;

      fetch(`${baseUrl}/${version}/admin/auth/profile`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          const { email, fullName } = responseJson;
          accountDetails = { email, fullName };
          localStorage.setItem("account", JSON.stringify(accountDetails));
          setAccount(accountDetails);
        });
    }

    setAccount(accountDetails);
  };

  return (
    <AccountLayout>
      <Box
        background="#FFFFFF"
        borderRadius="10px"
        py="50px"
        px="110px"
        p="50px 110px 100px"
      >
        <Text color="#252733" fontSize={24} fontWeight={500} mb="50px">
          Personal Details
        </Text>
        <Stack spacing="30px">
          <TextInput
            label="Full Name"
            placeholder="Full Name"
            value={account?.fullName || ''}
            disabled={true}
          />
          <TextInput
            label="Email Address"
            placeholder="Email Address"
            value={account?.email || ''}
            disabled={true}
          />
        </Stack>
      </Box>
    </AccountLayout>
  );
};

export default Details;
